.ql-editor {
  height: 300px !important;
}

audio {
  width: 250px !important;
  height: 54px;
}

.ant-input {
  color: #263238 !important;
}

.ant-layout-header {
  height: 50px !important;
  line-height: 43px !important;
}


.ql-container.ngx-quill-view-html {
  background-color: aliceblue;
  margin-bottom: 10px;
}


.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15vh;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-label-100 {
  width: 100%;
  margin-bottom: 0px;
  padding-left: 2px;
  color: #9ba7ad;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.form-zero {
  margin-bottom: 0px;
  border: 1px none #000;
  border-radius: 8px;
  background-color: #e5eaed;
}

.form-zero:hover {
  background-color: #d1d9dd;
}

.form-title-signin {
  margin-top: 0px;
  margin-bottom: 6px;
  margin-left: -3px;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #ff625c;
  font-size: 45px;
  line-height: 50px;
  font-weight: 300;
}

.body {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  font-family: 'Maven Pro', sans-serif;
}

.form-subtitle-sign-in {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #4c575d;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.form-subtitle-sign-in.mbottom {
  margin-bottom: 25px;
}

.form-title {
  width: 100%;
  margin-bottom: 20px;
}

.but-red-a {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 20px;
  border: 1px solid #ff625c;
  border-radius: 8px;
  background-color: #ff625c;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.but-red-a:hover {
  box-shadow: 0 10px 30px -10px rgba(255, 98, 92, 0.4);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.form-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.but-blue-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #4a90e2;
  border-radius: 8px;
  background-color: #4a90e2;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.but-blue-a:hover {
  border-style: solid;
  background-color: #4a90e2;
  box-shadow: 0 10px 30px -10px rgba(74, 144, 226, 0.4);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}

.form-block {
  width: 600px;
}

.form-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form-fields {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.form-field-50 {
  width: 100%;
  height: 40px;
  border: 1px none #000;
  border-radius: 12px;
  background-color: #e5eaed;
  color: #ff625c;
  font-size: 16px;
  line-height: 16px;
}

.form-bot-desactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 25px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #ff625c;
  border-radius: 8px;
}

.form-title-select {
  padding-top: 5px;
  padding-left: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}

.form-text-select {
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
}

.form-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.form-select {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-bot-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 25px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: #ff625c;
  box-shadow: 0 8px 40px 2px rgba(255, 98, 92, 0.2);
}

.form-title-gestor {
  padding-top: 5px;
  padding-left: 10px;
  color: #ff625c;
  font-size: 20px;
  font-weight: 700;
}

.form-balls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form-ball-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e5eaed;
  border-radius: 8px;
  background-color: #e5eaed;
  color: #6c777e;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.form-title-divide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-check {
  width: 14px;
  height: 14px;
  margin-left: 12px;
}

.form-ball-desactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  padding: 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #b9c5cc;
  border-radius: 8px;
  color: #b9c5cc;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.form-icon-big {
  width: 60px;
  height: 60px;
}

.form-50 {
  width: 50%;
}

.form-50.pright {
  padding-right: 10px;
}

.form-50.pleft {
  padding-left: 10px;
}

.form-but-sign-in-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
  padding: 12px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ff625c;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #ff625c;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  cursor: pointer;
}

.form-but-sign-in-3:hover {
  border-style: solid;
  background-color: #ff625c;
  box-shadow: 0 10px 30px -10px rgba(255, 98, 92, 0.39);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}

.form-title-login {
  margin-top: 0px;
  margin-bottom: 4px;
  margin-left: -3px;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #4a90e2;
  font-size: 45px;
  line-height: 50px;
  font-weight: 300;
}

.form-but-login-in {
  margin-right: 20px;
  padding: 12px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #0090d2;
  border-radius: 8px;
  background-color: #4a90e2;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  cursor: pointer;
}

.form-but-login-in:hover {
  box-shadow: 0 10px 30px -10px rgba(74, 144, 226, 0.4);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.form-filed-65 {
  width: 65%;
}

.form-filed-65.pright {
  padding-right: 10px;
}

.form-filed-35 {
  width: 35%;
}

.form-filed-35.pleft {
  padding-left: 10px;
}

.form-text-remember {
  color: #94a0a7;
  font-weight: 500;
}

.form-forget {
  display: block;
  padding: 8px 12px;
  border-radius: 12px;
  color: #9145d5;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
}

.form-forget:hover {
  background-color: #9145d5;
  box-shadow: 0 10px 30px -10px rgba(146, 69, 213, 0.4);
  color: #fff;
}

.form-title-recovery {
  margin-top: 0px;
  margin-bottom: 6px;
  margin-left: -3px;
  font-family: Ubuntu, Helvetica, sans-serif;
  color: #9145d5;
  font-size: 45px;
  line-height: 50px;
  font-weight: 300;
}

.but-purple-a {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #9145d5;
  border-radius: 8px;
  background-color: #9145d5;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.but-purple-a:hover {
  box-shadow: 0 10px 30px -10px rgba(146, 69, 213, 0.4);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.size-1200 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 10px 90px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  text-decoration: none;
}

.size-1200.zindex9999 {
  position: relative;
  z-index: 99;
}

.size-1200.topm {
  margin-top: 20px;
}

.size-1200.right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.size-1200.center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 170px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.bot-cadastrar {
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #ff625c;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
}

.bot-entrar {
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #22a9e1;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
}

.link-menu {
  padding: 10px 12px;
  color: #888;
  line-height: 14px;
  font-weight: 500;
  text-decoration: none;
}

.link-menu:hover {
  color: #088be0;
}

.menu-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 25px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c-w-line-top {
  display: none;
  width: 100%;
}

.col-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  padding-right: 2%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #4c575d;
  line-height: 14px;
  font-weight: 500;
}

.bot-grey-right-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 8px;
  padding: 8px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #4c575d;
  box-shadow: 0 5px 20px -5px rgba(76, 87, 94, 0.3);
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.bot-grey-right-active:hover {
  background-color: #394349;
  color: #fff;
}

.c-white-block {
  margin-bottom: 30px;
  border-radius: 16px;
  background-color: #e9e9e9;
  box-shadow: 0 10px 80px -20px rgba(0, 0, 0, 0.3);
}

.col-6 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #7c7c7c;
  font-size: 13px;
  line-height: 14px;
}

.c-w-p-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #4c575d;
  font-size: 14px;
  font-weight: 500;
}

.c-all {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
}

.col-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  padding-right: 2%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #4c575d;
  font-size: 13px;
  line-height: 14px;
}

.icon-search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('/assets/images/black-search-t.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.icon-search:hover {
  background-image: url('/assets/images/white-search.svg');
  background-position: 0px 0px;
  background-size: cover;
}

.col-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #4c575d;
  font-size: 13px;
  line-height: 14px;
}

.bot-troca {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-left: 5px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #eee;
  color: #727476;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.bot-troca:hover {
  background-color: #c5ced3;
}

.c-w-pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-w-p-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #babbbb;
  font-size: 13px;
  font-weight: 500;
}

.c-title-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.arrow-icon {
  height: 13px;
}

.bot-grey-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5px;
  padding: 8px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #d9dfe2;
  color: #727476;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.bot-grey-right:hover {
  background-color: #c5ced3;
}

.bot-grid-list {
  width: 14px;
  height: 14px;
}

.bot-busca {
  width: 100%;
  height: 40px;
  margin-bottom: 0px;
  padding: 3px 5px 5px 35px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 8px;
  background-color: #eee;
  background-image: url('/assets/images/black-search-t.svg');
  background-position: 7px 48%;
  background-size: 23px 23px;
  background-repeat: no-repeat;
  color: #4c575d;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  text-transform: none;
}

.bot-busca:hover {
  background-color: #c5ced3;
}

.bot-busca:focus {
  border-style: solid;
  border-color: #bbc0c4;
  background-size: 14px;
}

.bot-busca::-webkit-input-placeholder {
  color: #acacac;
}

.bot-busca:-ms-input-placeholder {
  color: #acacac;
}

.bot-busca::-ms-input-placeholder {
  color: #acacac;
}

.bot-busca::placeholder {
  color: #acacac;
}

.c-title-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.bot-pag-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 5px;
  padding: 8px 10px 8px 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #4c575d;
  color: #727476;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.bot-pag-right:hover {
  background-color: #353d42;
}

.col-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 2%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #4c575d;
  font-size: 13px;
  line-height: 14px;
}

.col-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90px;
  padding-right: 2%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #4c575d;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.c-w-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #707c83;
  box-shadow: 0 20px 30px -15px rgba(112, 124, 131, 0.2);
}

.c-w-line {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #fff;
  cursor: default;
}

.c-w-line:hover {
  position: static;
  background-color: #f3f3f3;
}

.c-title-text {
  color: #4c575d;
  font-size: 26px;
  line-height: 22px;
  font-weight: 400;
}

.c-title-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.c-w-p-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #d9dfe2;
  font-size: 13px;
  font-weight: 500;
}

.c-w-line-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.icon-arrow-down {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/black-arrow-down-t.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.icon-arrow-down:hover {
  background-image: url('/assets/images/white-arrow-down.svg');
  background-position: 50% 50%;
  background-size: auto;
}

.c-w-divisor {
  display: none;
  width: 98%;
  height: 1px;
  background-color: #d6d6d6;
}

.c-w-header-title {
  margin-right: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.bot-pag-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 12px;
  padding: 8px 12px 8px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #4c575d;
  color: #727476;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.bot-pag-left:hover {
  background-color: #353d42;
}

.c-w-bottom {
  height: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
}

.but-blue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #4a90e2;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #4a90e2;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.but-blue:hover {
  border-style: solid;
  background-color: #4a90e2;
  box-shadow: 0 10px 30px -10px rgba(74, 144, 226, 0.4);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}

.video-status-pill {
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #dfdfdf;
  color: #8f8f8f;
  font-size: 9px;
  line-height: 9px;
  font-weight: 700;
  text-transform: uppercase;
}

.status-verde {
  margin-top: 5px;
  padding: 3px 5px;
  border-radius: 12px;
  background-color: #3bc77a;
  color: #fff;
  font-size: 9px;
  line-height: 9px;
  font-weight: 500;
  text-transform: uppercase;
}

.link-menu-ativo {
  padding: 10px 12px;
  border: 1px none #000;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-decoration: none;
}

.link-menu-ativo:hover {
  color: #fff;
}

.c-w-line-content-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-4 {
  font-size: 12px;
  line-height: 12px;
}

.bot-criar-laudo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-left: 5px;
  padding: 8px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #6c7a83;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.bot-criar-laudo:hover {
  background-color: #4c575d;
  box-shadow: 0 10px 20px -10px #4c575d;
}

.c-photo {
  width: 120px;
  height: 120px;
  border-radius: 200px;
  background-image: url('/assets/images/doctor.jpg');
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: 7px 7px 50px -10px rgba(0, 0, 0, 0.2);
}

.bot-upload {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 15px;
  padding: 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #088be0;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.bot-upload:hover {
  background-color: #1a92c4;
  box-shadow: 0 10px 20px -10px rgba(34, 169, 225, 0.4);
}

.c-w-background-noeffect {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.c-w-background-noeffect:hover {
  position: static;
}

.form-field-100 {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border: 1px none #000;
  border-radius: 8px;
  background-color: #e5eaed;
  color: #ff625c;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.form-field-100:hover {
  background-color: #d1d9dd;
}

.form-minha-conta {
  width: 100%;
  margin-bottom: 0px;
}

.form2-perfil-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 12px;
  background-color: #e5eaed;
}

.form2-text-select {
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}

.form2-title-laudista {
  padding-top: 5px;
  padding-left: 10px;
  color: #5e5e5e;
  font-size: 20px;
  font-weight: 700;
}

.form-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.form-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33%;
  padding-top: 1vw;
  padding-bottom: 0.5vw;
  padding-left: 1vw;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.form2-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form-66 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 67%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.c-box-foto {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form2-line-vertical {
  width: 2px;
  height: 26px;
  background-color: #d1d9dd;
}

.form2-label-100 {
  width: 100%;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-left: 8px;
  color: #b6bfc4;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.c-w-divisor2 {
  width: 100%;
  height: 1px;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #d6d6d6;
}

.form-line-titulo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  color: #088be0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.form-bot-update {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 12px;
  background-color: #4a90e2;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
}

.c-w-content2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 97%;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-white-block2 {
  margin-bottom: 50px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 10px 80px -20px rgba(0, 0, 0, 0.15);
}

.form2-plans {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 23%;
  height: 200px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 12px;
  background-color: #e5eaed;
}

.form2-wrap-distribuido {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.c-w-plano-escolhido {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-w-30 {
  width: 30%;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.c-w-70 {
  width: 70%;
}

.c-w-divisor3 {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #d6d6d6;
}

.c-w-20 {
  width: 20%;
  color: #8d99a0;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.c-w-20-text {
  margin-top: 5px;
  color: #33373a;
  font-size: 16px;
  line-height: 17px;
  font-weight: 500;
}

.form-bot-remover {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 12px;
  background-color: #ff625c;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
}

.c-w-15 {
  width: 15%;
  color: #8d99a0;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.c-w-25 {
  width: 25%;
  color: #8d99a0;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.form2-plansselect {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 23%;
  height: 200px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 12px;
  background-color: #4c575d;
}

.c-w-title-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form-bot-cancel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 9px;
  padding: 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 12px;
  background-color: #ff625c;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
}

.c-w-20-pag {
  width: 25%;
  color: #000;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.c-w-25-plano {
  width: 25%;
  color: #727476;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}

.text-block-5 {
  color: #088be0;
  font-size: 14px;
  line-height: 14px;
}

.c-w-25-nota {
  width: 25%;
  color: #22a9e1;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.c-w-header-azul {
  width: 100%;
  height: 5px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #088be0;
}

.div-block-2 {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 14px 14px 80px -20px rgba(0, 0, 0, 0.3);
}

.size-1200-flutuante {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1300px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.menu-hamburguer {
  display: none;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.icon-menu {
  width: 30px;
  height: 30px;
}

.form-line-2titulo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  color: #088be0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.s-o-que-e-o-lav {
  margin-bottom: 180px;
}

.col30 {
  width: 30%;
}

.col30.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col30.plabel.noleft {
  width: 25%;
}

.col30.plabelr {
  padding-right: 10px;
}

.col30.plabell {
  padding-left: 10px;
}

.col30.plabell.noleft {
  width: 25%;
}

.form-input-msg {
  display: block;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 10px;
  padding-left: 10px;
  color: #ff625c;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

.col80 {
  width: 80%;
}

.col80.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col80.plabelr {
  padding-right: 10px;
}

.col80.plabell {
  padding-left: 10px;
}

.col60 {
  width: 60%;
}

.col60.nopadright {
  padding-right: 0px;
  padding-left: 25px;
}

.col60.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col60.plabelr {
  padding-right: 10px;
}

.col60.plabell {
  padding-left: 10px;
}

.col70 {
  width: 70%;
}

.col70._100percent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col70._100percent.pright {
  padding-right: 30px;
}

.col70.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col70.plabell {
  padding-left: 10px;
}

.col70.plabelr {
  padding-right: 10px;
}

.form-label {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0px;
  padding-left: 2px;
  color: #a8a8a8;
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
}

.form-label.td10 {
  margin-top: 10px;
}

.form-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px none #000;
  border-radius: 12px;
  background-color: #f3f3f3;
}

.form-input:hover {
  background-color: #e7e7e7;
}

.col10 {
  width: 10%;
}

.col10.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col40 {
  width: 40%;
}

.col40.nopadleft {
  padding-right: 25px;
  padding-left: 0px;
}

.col40.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col40.plabell {
  padding-left: 10px;
}

.col40.plabelr {
  padding-right: 10px;
}

.col40.plabelr.flex {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col40.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.col20 {
  width: 20%;
}

.col20.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col33 {
  width: 33.3333333%;
}

.col33.pright {
  padding-right: 15px;
}

.col33.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col33.plabell {
  padding-left: 10px;
}

.col33.plabelr {
  padding-right: 10px;
}

.col90 {
  width: 90%;
}

.col90.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col90.plabelr {
  padding-right: 10px;
}

.col90.plabell {
  padding-left: 10px;
}

.col75 {
  width: 75%;
}

.col75.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col75.plabell {
  padding-left: 10px;
}

.col75.plabelr {
  padding-right: 10px;
}

.col100 {
  width: 100%;
}

.col100.td15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.col100.down20 {
  margin-bottom: 20px;
}

.col100.top50 {
  margin-top: 50px;
}

.col100.td50 {
  margin-top: 50px;
}

.col100.td30 {
  margin-top: 30px;
}

.col100.td40 {
  margin-top: 40px;
}

.col100.wrap {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.col100.wrap.center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col100.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.col100.flex._30top {
  margin-top: 20px;
}

.col100.flex.margintop {
  margin-top: 30px;
}

.col100.flex.separete {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.col100.pad30 {
  padding: 20px 20px 0px;
}

.col100.pad30:hover {
  background-color: #f5f5f5;
}

.col100.topdown40 {
  padding-bottom: 30px;
}

.col100.padwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 10px 5px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col100.flexcenter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col100.flexcenter.margintb {
  margin-top: 30px;
  margin-bottom: 50px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col100.marginbot {
  margin-bottom: 30px;
}

.col100.mbottom {
  margin-bottom: 15px;
}

.col100.coment-but {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.col100.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col100.separate {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col100.separate.bmarg {
  margin-bottom: 12px;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.col25 {
  width: 25%;
}

.col25.pright {
  padding-right: 10px;
}

.col25.pright.mbottom {
  margin-bottom: 10px;
}

.col25.prightleft {
  padding-right: 10px;
  padding-left: 10px;
}

.col25.prightleft.mbottom {
  margin-bottom: 10px;
}

.col25.pleft {
  padding-left: 10px;
}

.col25.pleft.mbottom {
  margin-bottom: 10px;
}

.col25.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col25.plabelr {
  padding-right: 11px;
}

.col25.plabell {
  padding-left: 10px;
}

.col50 {
  width: 50%;
}

.col50.top20 {
  margin-top: 20px;
}

.col50.right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}

.col50.right.center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col50.col-left {
  width: 48%;
  padding-right: 0px;
  padding-left: 0px;
}

.col50.col-right {
  width: 48%;
  padding-right: 0px;
  padding-left: 0px;
}

.col50.flexcenter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col50.pright {
  padding-right: 15px;
}

.col50.pleft {
  padding-left: 15px;
}

.col50.wrap-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.col50.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col50.plabelr {
  padding-right: 10px;
}

.col50.plabell {
  padding-left: 10px;
}

.col66 {
  width: 66.66%;
}

.col66.plabel {
  padding-right: 10px;
  padding-left: 10px;
}

.col66.plabelr {
  padding-right: 10px;
}

.col66.plabell {
  padding-left: 10px;
}

.col100-no-vert {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.wrap.top50 {
  margin-top: 50px;
}

.wrap.mbottom {
  margin-bottom: 10px;
}

.check-field {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 100%;
  margin-right: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.check-image {
  width: 16px;
  height: 16px;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-remember-box2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #b9c5cc;
  border-radius: 5px;
  background-color: #e5eaed;
}

.form-ball-text {
  margin-left: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.col100-top50 {
  width: 100%;
  margin-top: 50px;
  padding: 10px;
}

.form-ball-pass {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  padding: 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ff625c;
  border-radius: 8px;
  background-color: #ff625c;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #4a90e2;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.blue-line {
  height: 1px;
  margin-left: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #4a90e2;
  line-height: 1px;
}

.check-white {
  width: 10px;
  height: 10px;
  margin-left: 4px;
}

.form-text {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-height: 250px;
  min-height: 250px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 12px;
  background-color: #e5eaed;
  color: #5e5e5e;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.form-text:hover {
  background-color: #d1d9dd;
}

.but-grey-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #94a0a7;
  border-radius: 8px;
  background-color: #94a0a7;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.but-grey-a:hover {
  border-style: solid;
  border-color: #94a0a7;
  background-color: #94a0a7;
  box-shadow: 0 10px 30px -10px rgba(76, 87, 94, 0.5);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}

.but-grey {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #94a0a7;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #94a0a7;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.but-grey:hover {
  border-style: solid;
  border-color: #94a0a7;
  background-color: #94a0a7;
  box-shadow: 0 10px 30px -10px rgba(76, 87, 94, 0.5);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}

.but-purple {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #9145d5;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #9145d5;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.but-purple:hover {
  box-shadow: 0 10px 30px -10px rgba(146, 69, 213, 0.4);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.but-red {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 20px;
  border: 1px solid #ff625c;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #ff625c;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.but-red:hover {
  background-color: #ff625c;
  box-shadow: 0 10px 30px -10px rgba(255, 98, 92, 0.4);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
  color: #fff;
}

.form-input-msg-2 {
  display: block;
  width: 100%;
  padding-left: 5px;
  color: #ff625c;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}

.relative {
  position: relative;
}

.error-box {
  padding: 20px;
  border-radius: 8px;
  background-color: #ff625c;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}

.menu-chat {
  position: relative;
  width: 40px;
  height: 40px;
  background-image: url('/assets/images/menu-chat_1menu-chat.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.menu-chat:hover {
  background-image: url('/assets/images/menu-chat2_1menu-chat2.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.user-seta {
  position: static;
  top: 60px;
  right: 0px;
  width: 30px;
  height: 15px;
  margin-right: 11px;
  background-color: #fff;
}

.menu-icones-texto {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.menu-links-novo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-topo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 6px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}

.user-bg {
  position: static;
  top: 60px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: auto;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 10px 80px -10px rgba(0, 0, 0, 0.25);
}

.user-sair {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fff;
}

.user-sair:hover {
  background-color: #088be0;
  color: #fff;
}

.user-linha {
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
}

.user-link {
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;
}

.menu-completo {
  position: relative;
  z-index: 99999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-foto {
  position: relative;
  width: 60px;
  height: 60px;
  margin-left: 10px;
  border: 2px solid #e3e3e3;
  border-radius: 100px;
  background-color: #ff625c;
  background-image: url('/assets/images/video-teste.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.menu-foto:hover {
  border-color: #0090d2;
}

.user-centro {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #088be0;
  cursor: pointer;
}

.user-centro:hover {
  color: #0090d2;
}

.user-absolute {
  position: absolute;
  top: 50px;
  right: 2px;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.menu-link {
  color: #5d5d5d;
  font-weight: 600;
  cursor: pointer;
}

.tab {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 7px 7px 30px -10px rgba(0, 0, 0, 0.2);
}

.tab-item {
  padding: 15px;
  font-family: 'Maven Pro', sans-serif;
  color: #5e5e5e;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.tab-item:hover {
  background-color: #088be0;
  color: #fff;
}

.tab-item-1 {
  padding: 15px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  font-family: 'Maven Pro', sans-serif;
  color: #5e5e5e;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.tab-item-1:hover {
  background-color: #088be0;
  color: #fff;
}

.tab-item-a {
  padding: 15px;
  background-color: #088be0;
  box-shadow: 0 10px 30px -10px rgba(34, 169, 225, 0.6);
  font-family: 'Maven Pro', sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.tab-item-a:hover {
  background-color: #088be0;
  color: #fff;
}

.lang-flag {
  width: 40px;
  height: 40px;
  padding: 8px;
  cursor: pointer;
}

.lang-flag:hover {
  border: 1px none #000;
  background-image: none;
}

.lang-block {
  position: relative;
  width: 40px;
  margin-right: 10px;
  margin-left: 10px;
}

.lang-flagbr {
  width: 40px;
  height: 40px;
  padding: 8px;
  cursor: pointer;
}

.lang-flagbr:hover {
  border: 1px none #000;
  background-image: none;
}

.lang-bg {
  position: absolute;
  top: -20px;
  width: 40px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.3);
}

.col-photo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.col-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c-w-line-photo {
  width: 58px;
  height: 35px;
  border-radius: 5px;
  background-image: url('/assets/images/video-teste.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.c-green-line-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24%;
  margin-top: 20px;
  padding-bottom: 60px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #f5f5f5;
  color: #3bc77a;
}

.c-white-block-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.c-w-line-content-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: 100%;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #f7f7f7;
}

.col-photo-card {
  overflow: hidden;
  width: 100%;
  height: 200px;
  border-radius: 8px 8px 0px 0px;
}

.c-w-line-photo-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  background-image: url('/assets/images/image-vertical.jpg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.col-content-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #f5f5f5;
}

.col-content-card.floot {
  padding-bottom: 50px;
}

.col-1-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 1%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col-2-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 1%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}

.col-3-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding-right: 1%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #7c7c7c;
  font-size: 13px;
  line-height: 14px;
}

.col-4-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 3px;
  padding-right: 1%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5e5e5e;
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
}

.col-6-card {
  position: relative;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #7c7c7c;
  font-size: 13px;
  line-height: 14px;
}

.c-w-divisor-card {
  display: none;
  width: 98%;
  height: 1px;
  background-color: #d6d6d6;
}

.col100-nopad {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.col100-nopad.wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.col100-nopad.mbot {
  margin-bottom: 20px;
}

.col100-nopad.mbot.wrap {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tags-header {
  padding: 15px;
  background-color: #fff;
}

.white-shadow {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.25);
}

.white-shadow.round {
  overflow: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tag-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tag-form.tag-form-mob {
  margin-top: 30px;
  margin-bottom: 30px;
}

.tag-form.tag-form-mob.duvidas {
  margin-top: 0px;
}

.tag-form.mtop {
  margin-top: 20px;
}

.tag-input {
  height: 50px;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px none #000;
  border-radius: 6px 0px 0px 6px;
  background-color: #e9e9e9;
}

.tag-but {
  height: 50px;
  padding: 12px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #088be0;
  font-weight: 700;
}

.tag-label {
  width: 60%;
  color: #0090d2;
  line-height: 14px;
}

.block-tag {
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.25);
}

.tag-time {
  width: 40%;
  color: #4c575d;
  line-height: 14px;
  text-align: right;
}

.tag-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.tag-coment-text {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.tag-hour {
  color: #b9b9b9;
  font-size: 12px;
  line-height: 11px;
  font-weight: 700;
}

.tag-name {
  font-size: 15px;
}

.tab-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
}

.tab-body.just-split {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tag-close {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #ff625c;
}

.close {
  width: 10px;
  height: 10px;
}

.tab-line {
  width: 100%;
  height: 5px;
  background-color: #088be0;
}

.video-title {
  color: #088be0;
  font-size: 26px;
  line-height: 22px;
  font-weight: 700;
}

.video-id {
  width: 100%;
  color: #b3b3b3;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

.video-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  margin-left: 15px;
  padding: 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #3bc77a;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.list-icon-edit {
  width: 32px;
  height: 32px;
  background-image: url('/assets/images/edit.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.list-icon-edit:hover {
  background-image: url('/assets/images/edit-a.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.list-icon-edit.padl10 {
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

.list-icon-edit.padr10 {
  width: 26px;
  height: 26px;
  margin-right: 5px;
}

.list-icon-share {
  width: 32px;
  height: 32px;
  background-image: url('/assets/images/share.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.list-icon-share:hover {
  background-image: url('/assets/images/share-a.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.list-icon-share.padl10 {
  width: 26px;
  height: 26px;
  margin-left: 5px;
}

.list-icon-share.padr10 {
  width: 26px;
  height: 26px;
  margin-right: 5px;
}

.list-icon-play {
  width: 32px;
  height: 32px;
  background-image: url('/assets/images/play.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.list-icon-play:hover {
  background-image: url('/assets/images/play-a.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.list-icon-play.padl10 {
  width: 26px;
  height: 26px;
  margin-left: 5px;
}

.list-icon-play.padr10 {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  background-image: url('/assets/images/video-play.svg');
}

.list-icon-trash {
  width: 32px;
  height: 32px;
  background-image: url('/assets/images/trash.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.list-icon-trash:hover {
  background-image: url('/assets/images/trash-a.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.list-icon-trash.padl10 {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  background-image: url('/assets/images/menu-cinza.svg');
}

.list-icon-trash.padr10 {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  background-size: cover;
}

.c-assinatura {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.c-signature {
  width: 60%;
  height: 120px;
  border-radius: 12px;
  background-image: url('/assets/images/doctor.jpg');
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: 7px 7px 50px -10px rgba(0, 0, 0, 0.2);
}

.icon-arrow-up {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/black-arrow-up-t.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.icon-arrow-up:hover {
  background-image: url('/assets/images/white-arrow-up.svg');
  background-position: 50% 50%;
  background-size: auto;
}

.submit-search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-field {
  height: 30px;
  margin-bottom: 0px;
  padding: 0px 8px;
  border: 1px none #000;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}

.text-field::-webkit-input-placeholder {
  color: #fff;
}

.text-field:-ms-input-placeholder {
  color: #fff;
}

.text-field::-ms-input-placeholder {
  color: #fff;
}

.text-field::placeholder {
  color: #fff;
}

.submit-button {
  height: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 0px 8px 8px 0px;
  background-color: rgba(0, 0, 0, 0.2);
}

.submit-button:hover {
  background-color: rgba(0, 0, 0, 0.41);
}

.form-input-search {
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #fff;
}

.icon-search-white {
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/black-search-t-white.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-100-100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-perspective: 60vw;
  perspective: 60vw;
}

.header-3d {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg) perspective(0px);
  transform: rotateX(50deg) rotateY(0deg) rotateZ(-30deg) perspective(0px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.print001 {
  position: absolute;
  width: 19vw;
  height: 13vw;
  background-color: #fff;
  box-shadow: -40px 69px 50px -10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(-176px, 143px, 14px);
  transform: translate3d(-176px, 143px, 14px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.print002 {
  position: absolute;
  width: 25vw;
  height: 17vw;
  background-color: #fff;
  box-shadow: -40px 69px 50px -10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(224px, 219px, 90px);
  transform: translate3d(224px, 219px, 90px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.print003 {
  position: absolute;
  width: 20vw;
  height: 16vw;
  background-color: #fff;
  box-shadow: -40px 69px 50px -10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(-262px, -210px, 124px);
  transform: translate3d(-262px, -210px, 124px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.print004 {
  position: absolute;
  width: 22vw;
  height: 17vw;
  background-color: #fff;
  box-shadow: -40px 69px 50px -10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(-400px, -105px, -14px);
  transform: translate3d(-400px, -105px, -14px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.print005 {
  position: absolute;
  width: 23vw;
  height: 18vw;
  background-color: #fff;
  box-shadow: -40px 69px 50px -10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(224px, -176px, -24px);
  transform: translate3d(224px, -176px, -24px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.print006 {
  position: absolute;
  width: 120px;
  height: 200px;
  background-color: #fff;
  box-shadow: -40px 69px 50px -10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(-76px, 110px, 71px);
  transform: translate3d(-76px, 110px, 71px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.print007 {
  position: absolute;
  width: 6vw;
  height: 9vw;
  background-color: #fff;
  box-shadow: -40px 69px 50px -10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(138px, 400px, -38px);
  transform: translate3d(138px, 400px, -38px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.section-2 {
  height: 700px;
}

.header-frase {
  position: absolute;
  z-index: 9999;
  color: #088be0;
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  text-align: center;
}

.s-news-letter {
  margin-bottom: 180px;
}

.tit-azul-2 {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #088be0;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
}

.text-cinza-2 {
  margin-top: 10px;
  color: #727476;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.form-bloco-100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-newsletter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  box-shadow: 0 20px 50px -20px rgba(7, 138, 223, 0.5);
}

.newsletter-field {
  height: 80px;
  margin-bottom: 0px;
  padding: 20px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px none #000;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}

.newsletter-field::-webkit-input-placeholder {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}

.newsletter-field:-ms-input-placeholder {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}

.newsletter-field::-ms-input-placeholder {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}

.newsletter-field::placeholder {
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}

.newsletter-button {
  width: 90px;
  height: 80px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #0376c2;
}

.tit-azul-1 {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #088be0;
  font-size: 36px;
  line-height: 36px;
  text-transform: none;
}

.text-cinza-1 {
  margin-top: 5px;
  color: #727476;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  text-align: justify;
}

.assista-video {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 44px;
  margin-top: 50px;
  margin-bottom: 15px;
  padding: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.oque-video {
  overflow: hidden;
  width: 100%;
  height: 350px;
  border-radius: 8px;
  background-image: url('/assets/images/video-teste.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.oque-bloco-branco {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 90px;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 15px 50px -15px rgba(0, 0, 0, 0.25);
  color: #088be0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.s-vantagens {
  margin-bottom: 40px;
}

.col100-no-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.card-001 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 380px;
  height: 230px;
  margin-right: 25px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#a33bff), to(#6305b7));
  background-image: linear-gradient(180deg, #a33bff, #6305b7);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  color: #fff;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
}

.card-icone {
  position: relative;
  z-index: 99;
  width: 50px;
  height: 50px;
}

.card-002 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 380px;
  height: 230px;
  margin-right: 25px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#35d3be), to(#09ab87));
  background-image: linear-gradient(180deg, #35d3be, #09ab87);
  color: #fff;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
}

.card-003 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 380px;
  height: 230px;
  margin-right: 25px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#94a0a7), to(#3a454b));
  background-image: linear-gradient(180deg, #94a0a7, #3a454b);
  color: #fff;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
}

.mensage-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.message-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.message-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 81px;
  margin-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mesage-title {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #22a9e1;
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
}

.message-date {
  margin-bottom: 10px;
}

.message-date-d-m-y {
  color: #2c2c2c;
  font-weight: 500;
  text-align: right;
}

.message-date-hour {
  color: #a7a7a7;
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  text-align: right;
}

.text-message {
  width: 100%;
  margin-top: 5px;
  font-size: 15px;
  line-height: 18px;
  text-align: justify;
}

.message-trash {
  margin-top: 10px;
  margin-bottom: 0px;
}

.message-exclude {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #ebebeb;
  color: #888;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  text-align: center;
}

.message-exclude:hover {
  color: #5e120f;
}

.line {
  height: 1px;
  background-color: #e6e6e6;
}

.read-more {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
}

.read-more:hover {
  color: #22a9e1;
}

.no-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #8d99a0;
  font-size: 20px;
  font-weight: 500;
}

.message-back {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #22a9e1;
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  text-align: center;
}

.message-back:hover {
  color: #09465f;
}

.menu-badget {
  position: absolute;
  left: auto;
  top: 4%;
  right: 4%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 16px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #ff625c;
  color: #fff;
  font-size: 12px;
  line-height: 13px;
  font-weight: 500;
  text-align: center;
}

.message-exclude2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #ff625c;
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  text-align: center;
}

.message-exclude2:hover {
  color: #5e120f;
}

.add-tag-block {
  padding: 15px;
  background-color: #fff;
}

.add-tag-but {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.tab-body-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.video-label {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #b9b9b9;
  font-size: 11px;
  line-height: 11px;
  font-weight: 700;
  text-transform: uppercase;
}

.video-text {
  color: #000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: justify;
}

.text-block-50 {
  width: 50%;
  margin-bottom: 20px;
  padding-right: 15px;
}

.video-text-title {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #088be0;
  line-height: 24px;
}

.text-block-100 {
  width: 100%;
  margin-bottom: 20px;
}

.form-block-2 {
  margin-bottom: 0px;
}

.info-video-tags {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.circle-tag {
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #e9e9e9;
}

.tag-hour2 {
  color: #999;
  font-size: 10px;
  line-height: 11px;
  font-weight: 700;
}

.tag-name2 {
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
}

.share-bg {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100vh;
  background-color: hsla(0, 0%, 100%, 0.8);
}

.text-block-25 {
  width: 25%;
  margin-bottom: 20px;
  padding-right: 15px;
}

.text-block-33 {
  width: 33.33%;
  margin-bottom: 20px;
  padding-right: 15px;
}

.commet-title {
  width: 100%;
  color: #088be0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.comment-header {
  width: 100%;
  padding: 15px;
  background-color: #fff;
}

.stars-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

.star-size {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.comment-label {
  width: 100%;
  color: #0090d2;
  line-height: 14px;
}

.comment-text {
  width: 100%;
  min-height: 80px;
  margin-bottom: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px none #000;
  border-radius: 6px;
  background-color: #e9e9e9;
  color: #088be0;
}

.comment-text.mob {
  width: 90%;
  min-height: 90px;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.comment-but {
  margin-left: 10px;
  padding: 12px 20px;
  border-radius: 6px;
  background-color: #088be0;
  font-weight: 700;
}

.comment-but.mob {
  min-width: 80px;
  margin-left: -1px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 12px;
}

.comment-name {
  margin-top: 2px;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
}

.comment-hour {
  margin-bottom: 0px;
  color: #b9b9b9;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
}

.comment-dr {
  margin-bottom: 0px;
  color: #088be0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

.comment-header-mob {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  background-color: #fff;
}

.white-share-block {
  position: relative;
  top: 15vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 600px;
  margin-right: auto;
  margin-left: auto;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 50px -2px rgba(0, 0, 0, 0.3);
}

.share-label {
  width: 100%;
  color: #b9c5cc;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.form-bot-enviar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding: 15px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 12px;
  background-color: #088be0;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.form-bot-enviar:hover {
  box-shadow: 0 8px 30px -8px #088be0;
}

.share-text {
  margin-bottom: 15px;
  color: #4c575d;
  font-size: 16px;
  line-height: 18px;
}

.share-subtitle {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #088be0;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.close-icon {
  position: absolute;
  left: auto;
  top: -15px;
  right: -15px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #ff625c;
  box-shadow: 0 6px 20px 0 #ff625c;
  cursor: pointer;
}

.close-icon:hover {
  background-color: #e0544f;
}

.close-share {
  width: 12px;
  height: 12px;
}

.list-icon-permission {
  width: 32px;
  height: 32px;
  background-image: url('/assets/images/play.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.list-icon-permission:hover {
  background-image: url('/assets/images/play-a.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.list-icon-permission.padl10 {
  width: 26px;
  height: 26px;
  margin-left: 5px;
  background-image: url('/assets/images/permission.svg');
}

.list-icon-permission.padl10:hover {
  background-image: url('/assets/images/permission-a.svg');
}

.list-icon-permission.padr10 {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  background-image: url('/assets/images/permission.svg');
}

.list-icon-permission.padr10:hover {
  background-image: url('/assets/images/permission-a.svg');
}

.time-des {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 10px 10px 10px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #e5eaed;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
}

.time-des:hover {
  background-color: #c6cfd4;
}

.time-act {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 10px 10px 10px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #4c575d;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #fff;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
}

.time-act:hover {
  box-shadow: 0 8px 30px -8px rgba(76, 87, 94, 0.4);
}

.header-blue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #088be0;
  color: #fff;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
}

.abs-check {
  position: absolute;
  left: auto;
  top: 0%;
  right: 12px;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 12px;
  height: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.white-permission-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 600px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 5px 50px -2px rgba(0, 0, 0, 0.3);
}

.white-permission-block.padb {
  position: relative;
  top: 15vh;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 15px;
}

.form-col70 {
  width: 66.67%;
}

.form-col33 {
  width: 33.33%;
}

.form-col33.pleft {
  padding-left: 10px;
}

.form-col33.pleftright {
  padding-right: 10px;
  padding-left: 10px;
}

.form-col33.pright {
  padding-right: 10px;
}

.form-col100 {
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form-col100.wrapright {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 35px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-col100.flexright {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.form-col40 {
  width: 40%;
}

.form-col40.pleft {
  padding-left: 10px;
}

.form-col60 {
  width: 60%;
}

.form-col60.pright {
  padding-right: 10px;
}

.form-col20 {
  width: 20%;
}

.form-col20.pleftright {
  padding-right: 10px;
  padding-left: 10px;
}

.form-col20.pleft {
  padding-left: 10px;
}

.time-cancel {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 10px 10px 10px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #e5eaed;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #ff625c;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
}

.time-cancel:hover {
  background-color: #ff625c;
  color: #fff;
}

.time-cancel-act {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 10px 10px 10px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #ff625c;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #fff;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
}

.time-cancel-act:hover {
  box-shadow: 0 8px 30px -8px rgba(255, 98, 92, 0.5);
}

.line-100 {
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 10px;
  background-color: #b9c5cc;
}

.icon-video {
  width: 20px;
  height: 20px;
}

.card-004 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 380px;
  height: 230px;
  margin-right: 25px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f95f5c), to(#ba1563));
  background-image: linear-gradient(180deg, #f95f5c, #ba1563);
  color: #fff;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
}

.card-005 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 380px;
  height: 230px;
  margin-right: 25px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3bc2c7), to(#0552b7));
  background-image: linear-gradient(180deg, #3bc2c7, #0552b7);
  color: #fff;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
}

.oque-bloco-branco2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 90px;
  padding-right: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  color: #4c575d;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: justify;
}

.portal-title {
  width: 100%;
  margin-bottom: 7px;
  color: #088be0;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
}

.portal-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 8px;
}

.price-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 90px;
  padding: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px 8px 0px 0px;
  background-color: #f2f2f2;
  color: #4c575d;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: justify;
}

.price-bot-plan-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 390px;
  height: 110px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 8px;
  background-color: #088be0;
  box-shadow: 0 6px 50px -10px rgba(8, 139, 224, 0.5);
}

.price-bot-text1-ative {
  width: 100%;
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
}

.price-bot-text2-ative {
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
}

.price-bot-plan-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 390px;
  height: 110px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 6px 50px -10px rgba(0, 0, 0, 0.2);
}

.price-bot-text1-des {
  width: 100%;
  color: #4c575d;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
}

.price-bot-text2-des {
  width: 100%;
  color: #b9c5cc;
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
}

.price-botright-model-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #088be0;
  border-radius: 0px 8px 8px 0px;
}

.price-bot-left-model-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #088be0;
  border-radius: 8px 0px 0px 8px;
  background-color: #088be0;
}

.price-bot-text3-des {
  width: 100%;
  color: #088be0;
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
}

.price-bot-text3-ative {
  width: 100%;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
}

.price-text-tit {
  color: #5e5e5e;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.price-box-title {
  width: 100%;
  margin-bottom: 10px;
  color: #088be0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}

.price-box-price1 {
  margin-bottom: 7px;
  color: #4c575d;
  font-size: 44px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -2px;
}

.price-box-pricers {
  margin-right: 5px;
  margin-bottom: 7px;
  color: #b9c5cc;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -1px;
}

.price-box-price2 {
  margin-bottom: 7px;
  color: #4c575d;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
  letter-spacing: -1px;
}

.price-but {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 0px 0px 8px 8px;
  background-color: #088be0;
  color: #4c575d;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-align: justify;
}

.price-but-text {
  width: 100%;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
}

.price-box-littletext {
  width: 100%;
  margin-bottom: 40px;
  color: #088be0;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  text-align: center;
}

.price-bullet-box-act {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
}

.price-bullet-icon {
  width: 16px;
  height: 16px;
}

.price-bullet-box-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #b9c5cc;
}

.price-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 330px;
  margin: 15px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.price-block:hover {
  box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.price-bot-left-model-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #088be0;
  border-radius: 8px 0px 0px 8px;
}

.price-botright-model-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #088be0;
  border-radius: 0px 8px 8px 0px;
  background-color: #088be0;
}

.price-text-subtit {
  margin-top: 5px;
  color: #9145d5;
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  text-align: center;
}

.s-portal {
  margin-bottom: 180px;
}

.s-preco {
  margin-bottom: 180px;
}

.s-empresa {
  padding-top: 100px;
  padding-bottom: 80px;
  background-color: #eef2f4;
}

.text-cinza-3 {
  margin-top: 0px;
  color: #8d99a0;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  text-align: justify;
}

.empresa-text1 {
  width: 100%;
  margin-bottom: 25px;
  color: #4c575d;
  font-size: 15px;
  line-height: 22px;
  text-align: justify;
}

.empresa-title1 {
  width: 100%;
  color: #088be0;
  font-size: 16px;
  font-weight: 700;
}

.flex-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.empresa-text2 {
  width: 33%;
  margin-bottom: 0px;
  color: #4c575d;
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
}

.empresa-text3 {
  width: 23%;
  margin-bottom: 0px;
  color: #4c575d;
  font-size: 15px;
  line-height: 20px;
  text-align: justify;
}

.s-contato {
  padding-top: 160px;
  background-color: #eef2f4;
  background-image: url('/assets/images/shutterstock_1197119827.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.white-form-block {
  width: 50%;
  margin-bottom: 30px;
  padding: 30px 30px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 80px -20px rgba(0, 0, 0, 0.3);
}

.form-col50 {
  width: 50%;
}

.form-col50.pleft {
  padding-left: 10px;
}

.form-col50.pright {
  padding-right: 10px;
}

.form-but-send {
  padding: 12px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #0090d2;
  border-radius: 8px;
  background-color: #4a90e2;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  cursor: pointer;
}

.form-but-send:hover {
  box-shadow: 0 10px 30px -10px rgba(74, 144, 226, 0.4);
  -webkit-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.rodape-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  margin-top: 120px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #088be0;
}

.rodape-text {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.div-block-4 {
  height: 2000px;
}

.block-line {
  width: 100%;
  height: 1px;
  background-color: #d9dfe2;
}

.block-line.m-top-bot {
  margin-top: 15px;
  margin-bottom: 15px;
}

.tab-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.tab-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32%;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
}

.tag-divide-line {
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #e5eaed;
}

.tab-desactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32%;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #e5eaed;
  color: #727476;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
}

.version-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  margin-right: 15px;
  padding: 10px 10px 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.version-icon {
  width: 16px;
  margin-left: 5px;
}

.video-tab-r-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #4c575d;
}

.video-control-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #4c575d;
}

.video-control-rbut {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 8px;
  background-color: #4c575d;
  color: #fff;
  cursor: pointer;
}

.video-control-rbut:hover {
  color: #088be0;
}

.video-control-text1 {
  width: 100%;
  font-size: 10px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.video-control-text2 {
  width: 100%;
  font-size: 14px;
  line-height: 10px;
  font-weight: 500;
  text-align: center;
}

.video-control-lbut {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-radius: 0px 0px 0px 8px;
  background-color: #4c575d;
  color: #fff;
  cursor: pointer;
}

.video-control-lbut:hover {
  color: #088be0;
}

.video-control-cbut {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #4c575d;
  color: #fff;
  cursor: pointer;
}

.video-control-cbut:hover {
  color: #088be0;
}

.video-control-line {
  width: 1px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.25);
}

.video-control-text3 {
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.m-video-tab-l-block {
  width: 100%;
  margin-top: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.m-tab-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 19%;
  height: 45px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
}

.m-tab-desactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 19%;
  height: 45px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #e5eaed;
  color: #727476;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.m-tab-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.block-doubt {
  margin-bottom: 30px;
}

.cancel-but {
  padding: 12px 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #8d99a0;
  border-radius: 6px;
  background-color: transparent;
  color: #8d99a0;
  font-weight: 500;
}

.cancel-but:hover {
  border-color: #ff625c;
  color: #ff625c;
}

.tags-headercolor {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #d9dfe2;
  color: #4c575d;
  line-height: 14px;
  font-weight: 500;
}

.responder-but {
  padding: 7px 15px;
  border-radius: 8px;
  background-color: #d9dfe2;
  color: #4c575d;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.responder-but:hover {
  background-color: #088be0;
  color: #fff;
}

.excluir-but {
  padding: 7px 15px;
  border-radius: 8px;
  background-color: #ff625c;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.tag-divide-line2 {
  width: 100%;
  height: 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #bdc5ca;
}

.tag-answer-text {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 8px;
  background-color: #f0f3f5;
  text-align: right;
}

.comment-answer-dr {
  margin-bottom: 0px;
  color: #ff625c;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

.comment-answer-hour {
  margin-bottom: 0px;
  color: #b9b9b9;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
}

.block-evaluate {
  margin-bottom: 30px;
}

.tag-evaluate-text {
  width: 100%;
  margin-bottom: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.evaluate-note {
  margin-right: 9px;
  color: #088be0;
  font-size: 30px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: -2px;
}

.tag-block-nowrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.block-laudos {
  margin-top: 45px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.block-pastas {
  width: 260px;
  margin-top: 45px;
  padding-top: 8px;
  padding-right: 20px;
}

.folder-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #707c83;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
}

.folder-shadow {
  position: relative;
  z-index: 99;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.3);
}

.folder-body {
  padding-top: 0px;
  padding-bottom: 1px;
  border: 1px none #000;
}

.folder-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4c575d;
  line-height: 14px;
  font-weight: 500;
}

.folder-des:hover {
  background-color: #ebeff1;
}

.folder-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.folder-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  background-color: #d1d9dd;
  color: #4c575d;
  line-height: 14px;
  font-weight: 500;
}

.folder-text-active {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.folder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.folder-create {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  margin-bottom: 9px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #707c83;
  border-radius: 4px;
  color: #707c83;
  font-weight: 500;
  text-align: center;
}

.folder-create:hover {
  background-color: #707c83;
  color: #fff;
}

.bot-excluir-pasta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  margin-left: 5px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ff625c;
  color: #fff;
  font-size: 10px;
  line-height: 8px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.bot-excluir-pasta:hover {
  background-color: #e74e48;
  box-shadow: 0 10px 20px -10px rgba(255, 98, 92, 0.4);
}

.excluir-pasta-icon {
  width: 14px;
  height: 14px;
}

.bot-mover-laudo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-left: 25px;
  padding: 8px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #d9dfe2;
  color: #727476;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.bot-mover-laudo:hover {
  background-color: #c5ced3;
  box-shadow: 0 10px 20px -10px #d9dfe2;
  color: #088be0;
}

.folder-create-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4c575d;
  line-height: 14px;
  font-weight: 500;
}

.form-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.form-create-folder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form-create-folder-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px none #000;
  border-radius: 4px 0px 0px 4px;
  background-color: #e5eaed;
  color: #4c575d;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.form-create-folder-input:hover {
  background-color: #d1d9dd;
}

.form-create-folder-input::-webkit-input-placeholder {
  color: #aab7be;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.form-create-folder-input:-ms-input-placeholder {
  color: #aab7be;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.form-create-folder-input::-ms-input-placeholder {
  color: #aab7be;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.form-create-folder-input::placeholder {
  color: #aab7be;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.submit-button-2 {
  width: 40px;
  height: 40px;
  border-radius: 0px 4px 4px 0px;
  background-color: #707c83;
  background-image: url('/assets/images/check-white.svg');
  background-position: 50% 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;
}

.m-block-exames {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px 15px 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  background-color: #fff;
}

.m-exame-title {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #088be0;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
}

.m-video-text-center {
  color: #000;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-align: left;
}

.m-video-label-center {
  margin-top: 0px;
  margin-bottom: 1px;
  color: #b9b9b9;
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.m-text-block-25 {
  width: 25%;
  margin-bottom: 10px;
  padding-right: 15px;
}

.m-text-block-50 {
  width: 50%;
  margin-bottom: 10px;
  padding-right: 15px;
}

.block-exames {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  padding: 20px 15px 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 6px 50px -10px rgba(0, 0, 0, 0.3);
}

.form-col25 {
  width: 25%;
}

.form-col25.pleft {
  padding-left: 10px;
}

.form-col75 {
  width: 75%;
}

.form-col75.pright {
  padding-right: 10px;
}

.video-box {
  overflow: hidden;
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.25);
}

.bg-icon {
  position: absolute;
  top: -60px;
  width: 400px;
  opacity: 0.05;
}

.text-block-101 {
  position: relative;
  z-index: 99;
}

.checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  margin-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 7px;
  background-color: transparent;
}

.grid-select-box {
  display: none;
}

.head-laudos-buscar {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.head-laudos-botoes {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.bot-grid-list2 {
  width: 16px;
  height: 16px;
}

.block-version-title {
  margin-bottom: 0px;
  color: #088be0;
  font-size: 26px;
  line-height: 22px;
  font-weight: 500;
}

.block-version-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  margin-bottom: 10px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.3);
}

.block-version-card-photo {
  width: 40%;
}

.block-version-card-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.version-photo {
  width: 100%;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
  background-image: url('/assets/images/video-teste.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.block-version-card-v {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 49%;
  margin-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.block-version-card-v:hover {
  box-shadow: 0 5px 40px -5px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.block-version-card-photo-v {
  width: 100%;
  height: 100px;
}

.block-version-card-content-v {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bot-troca-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-left: 5px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.bot-troca-text {
  margin-left: 6px;
}

.block-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #eee;
}

.form-filter {
  width: 100%;
  margin-bottom: 0px;
}

.form-filter-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.form-filter-input {
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px none #000;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.15);
  color: #088be0;
}

.form-filter-input::-webkit-input-placeholder {
  color: #fff;
}

.form-filter-input:-ms-input-placeholder {
  color: #fff;
}

.form-filter-input::-ms-input-placeholder {
  color: #fff;
}

.form-filter-input::placeholder {
  color: #fff;
}

.form-filter-label {
  margin-top: 0px;
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.form-filter-day {
  width: 20%;
  margin-right: 2%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form-filter-month {
  width: 20%;
  margin-right: 2%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form-filter-year {
  width: 50%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.form-filter-ate {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form-filter-text {
  margin-right: 5px;
}

.form-filter-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #088be0;
  font-size: 16px;
  line-height: 17px;
  font-weight: 700;
}

.form-filter-de {
  display: none;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form-filter-block-date {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.form-but {
  margin-left: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-weight: 700;
}

.version-but {
  padding: 7px 15px;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.version-but2 {
  padding: 7px 15px;
  border-radius: 8px;
  background-color: #d9dfe2;
  color: #5e5e5e;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.version-but3 {
  padding: 7px 15px;
  border-radius: 8px;
  background-color: #727476;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.modal-bg {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 99999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding-top: 90px;
  padding-bottom: 90px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-box {
  width: 60vw;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.3);
}

.modal-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  background-color: #088be0;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.modal-body {
  padding: 20px;
}

.modal-close {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px 8px 0px 0px;
}

.modal-close:hover {
  background-color: #026db2;
}

.modal-close-icon {
  width: 14px;
  height: 14px;
}

.user-name {
  position: absolute;
  left: -8px;
  top: auto;
  right: -8px;
  bottom: -11px;
  padding: 4px 5px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 4px 4px 20px -3px rgba(0, 0, 0, 0.4);
  color: #088be0;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  text-align: center;
}

.tab-content {
  padding: 30px;
  font-family: 'Maven Pro', sans-serif;
}

.tab2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  background-color: #fff;
}

.tab2-item {
  padding: 12px 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: 'Maven Pro', sans-serif;
  color: #5e5e5e;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.tab2-item:hover {
  background-color: #0475be;
  color: #fff;
}

.tab2-item-a {
  padding: 12px 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #0680cf;
  box-shadow: 0 10px 30px -10px rgba(4, 117, 190, 0.49);
  font-family: 'Maven Pro', sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.tab2-item-a:hover {
  background-color: #0475be;
  color: #fff;
}

.list-avatar-img {
  display: block;
  width: 28px;
  height: 28px;
  margin-right: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #c4c4c4;
  border-radius: 1000px;
  background-image: url('/assets/images/shutterstock_1197119827.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.status-vermelho {
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 3px 5px;
  border-radius: 12px;
  background-color: #ff625c;
  color: #fff;
  font-size: 9px;
  line-height: 9px;
  font-weight: 500;
  text-transform: uppercase;
}

.list-block-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #707c83;
  font-size: 13px;
  line-height: 12px;
  font-weight: 500;
}

.list-icon-request {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 7px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #3bc77a;
  border-radius: 20px;
  color: #3bc77a;
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  text-align: center;
}

.list-icon-request:hover {
  background-color: #3bc77a;
  color: #fff;
}

.list-name {
  color: #4c575d;
}

.list-email {
  margin-top: 3px;
  font-size: 10px;
  line-height: 10px;
}

.list-phone {
  margin-top: 2px;
  font-size: 10px;
  line-height: 10px;
}

.block-qrcode {
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.25);
}

.tab-qrcode {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
}

.tab-qrcode-image {
  width: 150px;
  height: 150px;
  background-image: url('/assets/images/shutterstock_1197119827.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.tab-qrcode-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.tab-qrcode-bot {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding: 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #3bc77a;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.tab-qrcode-bot:hover {
  color: #0c5f31;
}

.tab-qrcode-text {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 14px;
}

.c-r-line {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #fde6e6;
}

.c-r-line:hover {
  position: static;
  background-color: #ffd0d0;
}

.c-g-line {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #fff;
}

.c-g-line:hover {
  position: static;
  background-color: #c8fade;
}

.c-b-line {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #e0f6ff;
}

.c-b-line:hover {
  position: static;
  background-color: #ccecfa;
}

.c-y-line {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #ffffe1;
}

.c-y-line:hover {
  position: static;
  background-color: #fbfcc2;
}

.c-gr-line {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #e4e4e4;
}

.c-gr-line:hover {
  position: static;
  background-color: #d1d0d0;
}

.list-titlename {
  display: none;
}

.list-titlename-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  color: #b9c5cc;
  font-size: 9px;
  line-height: 8px;
  font-weight: 700;
}

.c-g-line-content-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
}

.c-b-line-content-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: 100%;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #e0f6ff;
}

.c-y-line-content-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: 100%;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #ffffe1;
}

.c-gr-line-content-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: 100%;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.version-button-folder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  margin-bottom: 12px;
  padding: 10px 10px 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.video-background {
  overflow: hidden;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
}

.video-control-cbut2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  color: #fff;
  cursor: pointer;
}

.video-control-cbut2:hover {
  color: #088be0;
}

.block-organizacoes {
  width: 300px;
  margin-top: 45px;
  padding-right: 20px;
}

.block-organizacoes-aberto {
  margin-top: 45px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.org-block {
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.org-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #707c83;
  color: #fff;
  font-size: 18px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
}

.org-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.org-des:hover {
  background-color: #f1f1f1;
}

.org-des-image {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background-image: url('/assets/images/1200px-Palmeiras_logo.svg.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 6px 30px -5px rgba(0, 0, 0, 0.2);
}

.org-des-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.org-des-title {
  width: 100%;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  line-height: 14px;
  font-weight: 700;
}

.org-des-members {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.35);
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.org-lig {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f1f1f1;
}

.org-tab-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 19%;
  height: 45px;
  margin-right: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
}

.org-tab-desactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 19%;
  height: 45px;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #e5eaed;
  color: #727476;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.org-tab-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.org-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #088be0;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}

.org-w-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #088be0;
  box-shadow: 0 20px 30px -15px rgba(112, 124, 131, 0.2);
}

.org-col-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  padding-right: 2%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #4c575d;
  font-size: 12px;
  line-height: 10px;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.org-col-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  padding-right: 2%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #4c575d;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
}

.org-col-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  padding-right: 2%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #4c575d;
  font-size: 13px;
  line-height: 14px;
}

.org-col-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #4c575d;
  font-size: 13px;
  line-height: 14px;
}

.org-col-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.org-w-line-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.org-list-block-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #707c83;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}

.org-w-line-content-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.org-but-normal-act {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  margin-right: 5%;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #4c575d;
  border-radius: 20px;
  background-color: #4c575d;
  color: #fff;
  font-size: 11px;
  line-height: 10px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: default;
}

.org-but-admin-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #b9c5cc;
  border-radius: 20px;
  color: #b9c5cc;
  font-size: 11px;
  line-height: 10px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.org-but-admin-des:hover {
  border-color: #ff625c;
  color: #ff625c;
}

.org-but-admin-act {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ff625c;
  border-radius: 20px;
  background-color: #ff625c;
  color: #fff;
  font-size: 11px;
  line-height: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.org-but-normal-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 20px;
  margin-right: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #b9c5cc;
  border-radius: 20px;
  background-color: transparent;
  color: #b9c5cc;
  font-size: 11px;
  line-height: 10px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.org-but-normal-des:hover {
  border-color: #4c575d;
  color: #4c575d;
}

.org-but-invite {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding: 5px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  cursor: pointer;
}

.org-but-invite:hover {
  background-color: #fff;
  color: #088be0;
}

.org-bar-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-filter2 {
  width: 100%;
  margin-bottom: 0px;
}

.org-busca {
  padding-right: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.org-input {
  height: 30px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #2776be;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.15);
  color: #088be0;
}

.org-input::-webkit-input-placeholder {
  color: #fff;
}

.org-input:-ms-input-placeholder {
  color: #fff;
}

.org-input::-ms-input-placeholder {
  color: #fff;
}

.org-input::placeholder {
  color: #fff;
}

.org-line-size {
  height: 5px;
}

.org-w-header-line {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 8px;
  background-color: #088be0;
  box-shadow: 0 20px 30px -15px rgba(112, 124, 131, 0.2);
}

.org-col-content-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.org-tab2-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24%;
  height: 45px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
}

.org-tab2-desactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24%;
  height: 45px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #e5eaed;
  color: #727476;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.org-tab2-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.org2-col-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #4c575d;
  font-size: 12px;
  line-height: 10px;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.modal-box2 {
  width: 70vw;
  max-width: 1200px;
  min-width: 800px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.3);
}

.comp-text-2 {
  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
}

.comp-text-1 {
  width: 100%;
  color: #505b61;
  font-weight: 500;
}

.comp-tabs-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-family: 'Maven Pro', sans-serif;
}

.comp-w-block-left {
  overflow: hidden;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}

.comp-w-line {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #f5f5f5;
  cursor: default;
}

.comp-w-line:hover {
  position: static;
  background-color: #ebebeb;
}

.comp-w-line-active {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #e5eaed;
  cursor: default;
}

.comp-w-line-active:hover {
  position: static;
  background-color: #d8d8d8;
}

.comp-w-block-comp {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.25);
}

.comp-name-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.comp-block-but {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.comp-but-share {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
}

.org2-col-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #4c575d;
  font-size: 12px;
  line-height: 10px;
  font-weight: 500;
}

.col38 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 38%;
}

.comp-b-line {
  height: 5px;
  background-color: #088be0;
}

.share-box {
  width: 70vw;
  max-width: 1200px;
  min-width: 800px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 30px 50px -10px rgba(0, 0, 0, 0.3);
}

.share-body {
  padding: 20px;
}

.share-tab-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32%;
  height: 45px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #088be0;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
}

.share-tab-desactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32%;
  height: 45px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #e5eaed;
  color: #727476;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.share-tab-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.col48 {
  width: 48%;
}

.share-create-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #727476;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
}

.div-block-5 {
  height: 500px;
}

.div-block-6 {
  height: 1000px;
}

.absolute {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.vanquish-logo {
  z-index: 10000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.vanquish-relative {
  position: relative;
}

.vanquish-absolute {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  padding: 8px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top-right-radius: 10px;
  background-color: #fff;
  font-size: 10px;
  line-height: 18px;
}

.vanquish-sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 100vh;
  right: auto;
  bottom: auto;
  z-index: 10000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.video-logo-empresa {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 100px;
  background-color: #e6e6e6;
  background-image: url('/assets/images/1200px-Palmeiras_logo.svg.png');
  background-position: 50% 50%;
  background-size: cover;
}

.video-card-empresa {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.video-logo-empresa-nome {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #5e5e5e;
  font-size: 18px;
  font-weight: 700;
}

.video-logo-text {
  width: 100%;
  font-size: 16px;
}

.video-logo-emissor {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 100px;
  background-image: url('/assets/images/doctor.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.video-absolute {
  position: absolute;
  top: 30px;
  right: -10px;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.video-seta {
  position: static;
  top: 60px;
  right: 0px;
  width: 30px;
  height: 15px;
  margin-right: 15px;
  background-color: #fff;
}

.video-icon-option {
  width: 18px;
}

.video-icon-play {
  width: 12px;
}

.video-green-ball {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #3bc77a;
  cursor: pointer;
}

.video-green-ball:hover {
  background-color: #2ca864;
}

.video-icon-option-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
}

.video-icon-option-block:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.video-icon2 {
  width: 22px;
  margin-right: 8px;
  opacity: 0.4;
}

.user-baixo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 6px;
  padding-left: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}

.div-block-10 {
  height: 1000px;
}

.div-block-11 {
  background-color: #ff625c;
}

.div-block-12 {
  background-color: #9145d5;
}

.div-block-13 {
  height: 1000px;
}

.container-a {
  overflow: hidden;
  height: 100vh;
}

.collum-header-aba {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  height: 40px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #ddd;
  color: #646464;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.collum-header-aba:hover {
  color: #088be0;
}

.collum-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.collum-header-aba-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  height: 40px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #707c83;
  box-shadow: 0 -6px 30px -8px rgba(0, 0, 0, 0.21);
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.collum-search {
  width: 100%;
  padding: 10px;
  background-color: #707c83;
}

.form-field-zero {
  width: 100%;
  height: 40px;
  margin-bottom: 0px;
  border: 1px none #000;
  border-radius: 4px;
  background-color: #5c676d;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
}

.form-field-zero:hover {
  background-color: #4e5a61;
}

.form-field-zero::-webkit-input-placeholder {
  color: #1f2a30;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
}

.form-field-zero:-ms-input-placeholder {
  color: #1f2a30;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
}

.form-field-zero::-ms-input-placeholder {
  color: #1f2a30;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
}

.form-field-zero::placeholder {
  color: #1f2a30;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
}

.enterprise-icon {
  width: 40px;
  height: 40px;
  margin-right: 6px;
  border-radius: 100px;
  background-image: url('/assets/images/1200px-Palmeiras_logo.svg.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.enterprise-des {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4c575d;
  line-height: 14px;
  font-weight: 500;
}

.enterprise-des:hover {
  background-color: #ebeff1;
}

.enterprise-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #d1d9dd;
  color: #4c575d;
  line-height: 14px;
  font-weight: 500;
}

.list-icon-logs {
  width: 32px;
  height: 32px;
  background-image: url('/assets/images/edit.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.list-icon-logs:hover {
  background-image: url('/assets/images/edit-a.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.list-icon-logs.padr10 {
  width: 26px;
  height: 26px;
  margin-right: 5px;
}

.video-play-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: hsla(0, 0%, 100%, 0.7);
  background-image: url('/assets/images/video-play.svg');
  background-position: 50% 50%;
  background-size: 25px;
  background-repeat: no-repeat;
  box-shadow: 1px 1px 40px 0 rgba(0, 0, 0, 0.3);
}

.video-play-center:hover {
  background-color: #088be0;
}

.video-play {
  width: 26px;
  height: 26px;
  margin-left: 3px;
  background-image: url('/assets/images/video-play.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.date-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
}

.text-date {
  margin-right: 10px;
}

.mob-header {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #088be0;
  box-shadow: 0 1px 50px 0 rgba(8, 139, 224, 0.25);
  color: #fff;
}

.mob-menu {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 100vh;
  right: 0%;
  bottom: 0%;
  z-index: 9999999;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
}

.mob-menu-bg {
  position: absolute;
  width: 100%;
  height: 150px;
  margin-top: -90px;
  background-color: #fff;
  box-shadow: 0 1px 50px -10px rgba(0, 0, 0, 0.3);
}

.mob-sizefull {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}

.mob-sizefull.space {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
}

.mob-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  color: #6b7175;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
}

.mob-icon-image {
  width: 34px;
  height: 34px;
  margin-top: 5px;
}

.mob-icon-txt {
  width: 100%;
  margin-top: 4px;
}

.mob-icon-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  color: #088be0;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.line-green {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 5px;
  background-color: #3bc77a;
}

.c-blue-line-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24%;
  margin-top: 20px;
  padding-bottom: 60px;
  border-radius: 8px;
  background-color: #f5f5f5;
  color: #088be0;
}

.video-blue-ball {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #088be0;
  cursor: pointer;
}

.video-blue-ball:hover {
  background-color: #056bad;
}

.c-red-line-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49%;
  margin-top: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #ff625c;
  color: #ff625c;
}

.video-red-ball {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #ff625c;
  cursor: pointer;
}

.video-red-ball:hover {
  background-color: #dd4b45;
}

.c-grey-line-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24%;
  margin-top: 20px;
  padding-bottom: 60px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #f5f5f5;
  color: #4c575d;
}

.video-grey-ball {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #4c575d;
  cursor: pointer;
}

.video-grey-ball:hover {
  background-color: #30383d;
}

.video-yellow-ball {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #ffe100;
  cursor: pointer;
}

.video-yellow-ball:hover {
  background-color: #d6bd00;
}

.c-yellow-line-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49%;
  margin-top: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #ffe100;
  color: #d1b900;
}

.c-yellow-line-card:hover {
  position: static;
}

.c-purple-line-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49%;
  margin-top: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #6d00ce;
  color: #6d00ce;
}

.c-purple-line-card:hover {
  position: static;
}

.video-purple-ball {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #6d00ce;
  cursor: pointer;
}

.funcions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.mob-card-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  padding-right: 1%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}

.mob-card-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 5px;
  padding-right: 1%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #7c7c7c;
  font-size: 13px;
  line-height: 14px;
}

.mob-card-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 5px;
  padding-right: 1%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5e5e5e;
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
}

.mob-card-4 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #7c7c7c;
  font-size: 13px;
  line-height: 14px;
}

.video-light-ball {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #e4e4e4;
  cursor: pointer;
}

.video-light-ball:hover {
  background-color: #acacac;
}

.head-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
}

.head-back {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  padding: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-green-bar {
  width: 5px;
  height: 100%;
  background-color: #3bc77a;
}

.video-line-play {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
}

.video-line-play:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.video-absolute-copy {
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.video-icon-close {
  width: 12px;
}

.col-content-option {

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #f5f5f5;
}

.col-content-option.floot {
  padding-bottom: 50px;
}

.grid-trash {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  height: 50%;
  min-height: 100px;
  padding-top: 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-image: url('/assets/images/g-video-trash.svg');
  background-position: 50% 38%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #4c575d;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.grid-trash:hover {
  background-color: #4c575d;
  background-image: url('/assets/images/w-video-trash.svg');
  color: #fff;
}

.grid-image {
  width: 24px;
  height: 24px;
  opacity: 0.6;
}

.grid-text {
  width: 100%;
  margin-top: 5px;
}

.grid-icon-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  height: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-color: #4c575d;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
}

.grid-icon-a:hover {
  color: #fff;
}

.grid-image-a {
  width: 24px;
  height: 24px;
}

.grid-icon-lav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  height: 50%;
  min-height: 100px;
  padding-top: 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-image: url('/assets/images/g-video-plug.svg');
  background-position: 50% 38%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #4c575d;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.grid-icon-lav:hover {
  background-color: #4c575d;
  background-image: url('/assets/images/w-video-plug.svg');
  color: #fff;
}

.grid-icon-edit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  height: 50%;
  min-height: 100px;
  padding-top: 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-image: url('/assets/images/g-video-edit.svg');
  background-position: 50% 38%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #4c575d;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.grid-icon-edit:hover {
  background-color: #4c575d;
  background-image: url('/assets/images/w-video-edit.svg');
  color: #fff;
}

.grid-share {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  height: 50%;
  min-height: 100px;
  padding-top: 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-image: url('/assets/images/g-video-share.svg');
  background-position: 50% 38%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #4c575d;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.grid-share:hover {
  background-color: #4c575d;
  background-image: url('/assets/images/w-video-share.svg');
  color: #fff;
}

.grid-log {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  height: 50%;
  min-height: 100px;
  padding-top: 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-image: url('/assets/images/g-logs.svg');
  background-position: 50% 38%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #4c575d;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.grid-log:hover {
  background-color: #4c575d;
  background-image: url('/assets/images/w-logs.svg');
  color: #fff;
}

.grid-permission {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  height: 50%;
  min-height: 100px;
  padding-top: 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background-image: url('/assets/images/g-video-permission.svg');
  background-position: 50% 38%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #4c575d;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.grid-permission:hover {
  background-color: #4c575d;
  background-image: url('/assets/images/w-video-permission.svg');
  color: #fff;
}

.line-blue {
  z-index: 1;
  width: 100%;
  height: 5px;
  background-color: #088be0;
}

.line-grey {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 5px;
  background-color: #4c575d;
}

.conclusion-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #eee;
  font-size: 16px;
  line-height: 20px;
}

.conclusion-title {
  width: 100%;
  color: #a3aeb4;
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.conclusion-text {
  width: 100%;
}

.video-control-block-thumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #4c575d;
}

.enterprise-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.enterprise-label-red {
  margin-top: 4px;
  padding: 3px 7px;
  border-radius: 10px;
  background-color: #ff625c;
  color: #fff;
  font-size: 10px;
  line-height: 9px;
  font-weight: 500;
  text-transform: uppercase;
}

.enterprise-title {
  width: 100%;
}

.enterprise-label-blue {
  margin-top: 4px;
  padding: 3px 7px;
  border-radius: 10px;
  background-color: #088be0;
  color: #fff;
  font-size: 10px;
  line-height: 9px;
  font-weight: 500;
  text-transform: uppercase;
}

.enterprise-label-green {
  margin-top: 4px;
  padding: 3px 7px;
  border-radius: 10px;
  background-color: #3bc77a;
  color: #fff;
  font-size: 10px;
  line-height: 9px;
  font-weight: 500;
  text-transform: uppercase;
}

.form-field {
  border-radius: 6px;
}

@media screen and (max-width: 991px) {
  .but-blue-a:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .form-block {
    width: 80%;
  }
  .form-but-sign-in-3:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .form-but-login-in:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .but-purple-a:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .size-1200 {
    padding-top: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .size-1200.topm {
    margin-top: 0px;
  }
  .logo {
    width: 150px;
  }
  .menu-links {
    display: none;
  }
  .c-w-line-top {
    position: relative;
    z-index: 1;
    display: none;
    width: 4px;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #4a90e2;
  }
  .col-2 {
    width: 100%;
    margin-top: 10px;
    padding-right: 0%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    color: #4a90e2;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }
  .c-white-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    background-color: transparent;
    box-shadow: none;
  }
  .col-6 {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .col-3 {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 0%;
    font-size: 14px;
    font-weight: 500;
  }
  .col-5 {
    width: 100%;
    margin-top: 22px;
  }
  .c-title-right {
    width: 100%;
  }
  .bot-busca {
    margin-left: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-title-left {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .col-4 {
    width: 100%;
    padding-right: 0%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    color: #3a3f43;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }
  .col-1 {
    width: 100%;
    padding-right: 0%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
  .c-w-header {
    display: none;
    padding-right: 2.5%;
    padding-left: 2.5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .c-w-line {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 2.5vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 10px 50px -18px rgba(0, 0, 0, 0.2);
  }
  .c-title-text {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 22px;
  }
  .c-w-p-left {
    display: none;
  }
  .c-w-line-content {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-w-divisor {
    display: none;
  }
  .c-w-header-title {
    display: block;
  }
  .c-w-bottom {
    display: none;
  }
  .but-blue:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .video-status-pill {
    margin-top: 0px;
  }
  .c-w-line-content-header {
    padding-left: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-w-background-noeffect {
    position: relative;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 10px 50px -18px rgba(0, 0, 0, 0.2);
  }
  .form-minha-conta {
    width: 100%;
  }
  .form-33 {
    display: none;
    width: 20%;
  }
  .form-66 {
    width: 100%;
    margin-bottom: 2vw;
  }
  .c-w-divisor2 {
    display: none;
  }
  .c-w-content2 {
    margin-top: 0px;
    padding: 3vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-white-block2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    background-color: transparent;
    box-shadow: none;
  }
  .c-w-divisor3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .c-w-20 {
    width: 50%;
    margin-bottom: 2vw;
  }
  .c-w-20-text {
    margin-top: 2px;
  }
  .c-w-15 {
    width: 50%;
    margin-bottom: 2vw;
  }
  .c-w-25 {
    width: 50%;
    margin-bottom: 2vw;
  }
  .c-w-20-pag {
    width: 50%;
    margin-bottom: 1vw;
  }
  .c-w-25-plano {
    width: 50%;
  }
  .c-w-header-azul {
    display: none;
  }
  .menu-hamburguer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 60px;
    padding: 15px 16px;
  }
  .col30.tag {
    display: none;
  }
  .col30.plabel.noleft {
    width: 50%;
    margin-top: 15px;
    padding-left: 0px;
  }
  .col30.plabell.noleft {
    width: 50%;
    margin-top: 15px;
  }
  .col60 {
    width: 100%;
  }
  .col70._100percent {
    width: 100%;
  }
  .col70._100percent.pright {
    padding-right: 0px;
  }
  .col40.plabelr.flex {
    width: 100%;
    padding-right: 0px;
  }
  .col100.wrap {
    padding-right: 0px;
    padding-left: 0px;
  }
  .col25 {
    width: 50%;
  }
  .col50 {
    width: 100%;
  }
  .col50.right {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 11px;
    line-height: 11px;
    text-align: left;
  }
  .col50.pright {
    padding-right: 0px;
  }
  .col50.pleft {
    padding-left: 0px;
  }
  .but-grey-a:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .but-grey:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .but-purple:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .menu-icones-texto {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .menu-foto {
    margin-right: 0px;
  }
  .menu-link {
    display: none;
  }
  .col-photo {
    width: 30%;
    height: auto;
  }
  .col-content {
    width: 70%;
    margin-right: 0px;
    padding: 2vw;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .c-w-line-photo {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .c-green-line-card {
    width: 49%;
    clear: left;
    border-radius: 8px;
    background-color: #f5f5f5;
  }
  .c-white-block-card {
    background-color: transparent;
    box-shadow: none;
  }
  .c-w-line-content-card {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .col-photo-card {
    width: 100%;
    height: 25vw;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .c-w-line-photo-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .col-content-card.floot {
    min-height: 55%;
  }
  .col-1-card {
    padding-right: 0%;
    font-size: 11px;
    line-height: 11px;
  }
  .col-3-card {
    display: none;
  }
  .col-4-card {
    display: none;
    width: 50%;
  }
  .col-6-card {
    display: none;
  }
  .c-w-divisor-card {
    display: none;
  }
  .col100-nopad.mbot.wrap {
    margin-bottom: 15px;
  }
  .block-tag {
    overflow: visible;
    box-shadow: none;
  }
  .video-title {
    margin-bottom: 5px;
  }
  .list-icon-edit.padl10 {
    margin-left: 0px;
  }
  .list-icon-edit.padr10 {
    background-image: url('/assets/images/video-edit.svg');
  }
  .list-icon-share.padr10 {
    background-image: url('/assets/images/video-share.svg');
  }
  .list-icon-play.padr10 {
    background-image: url('/assets/images/video-play.svg');
  }
  .list-icon-trash.padr10 {
    background-image: url('/assets/images/video-trash.svg');
  }
  .header-frase {
    font-size: 40px;
    line-height: 38px;
  }
  .col100-no-wrap {
    padding-right: 10px;
    padding-left: 10px;
  }
  .text-block-50 {
    padding-right: 15px;
  }
  .text-block-25 {
    padding-right: 15px;
  }
  .text-block-33 {
    padding-right: 15px;
  }
  .list-icon-permission.padr10 {
    background-image: url('/assets/images/video-permission.svg');
  }
  .form-col33.pleft {
    padding-left: 5px;
  }
  .form-col33.pleftright {
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-col33.pright {
    padding-right: 5px;
  }
  .form-col40.pleft {
    padding-left: 5px;
  }
  .form-col60.pright {
    padding-right: 5px;
  }
  .form-col20.pleftright {
    padding-right: 5px;
    padding-left: 5px;
  }
  .form-col20.pleft {
    padding-left: 5px;
  }
  .oque-bloco-branco2 {
    padding-right: 0px;
  }
  .price-bot-plan-active {
    width: 250px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .price-bot-text1-ative {
    font-size: 18px;
  }
  .price-bot-text2-ative {
    font-size: 12px;
  }
  .price-bot-plan-des {
    width: 250px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .price-bot-text1-des {
    font-size: 18px;
  }
  .price-bot-text2-des {
    font-size: 12px;
  }
  .empresa-text2 {
    width: 45%;
    margin-bottom: 15px;
  }
  .empresa-text3 {
    width: 45%;
  }
  .white-form-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    box-shadow: none;
  }
  .form-col50.pleft {
    padding-left: 5px;
  }
  .form-col50.pright {
    padding-right: 5px;
  }
  .form-but-send:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .folder-header {
    display: none;
    padding-right: 2.5%;
    padding-left: 2.5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .m-text-block-25 {
    padding-right: 15px;
  }
  .m-text-block-50 {
    padding-right: 15px;
  }
  .form-col25.pleft {
    padding-left: 5px;
  }
  .checkbox {
    display: none;
  }
  .grid-select-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 100px;
    background-color: #d1d9dd;
    color: #9ca7ad;
    font-size: 10px;
    line-height: 10px;
  }
  .grid-select-box-text {
    margin-right: 7px;
  }
  .block-version-title {
    margin-bottom: 15px;
  }
  .block-version-card {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .block-version-card-photo {
    width: 30%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .block-version-card-content {
    width: 70%;
    height: 23vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    padding-left: 3vw;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .version-photo {
    width: 100%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .block-version-card-v {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .block-version-card-photo-v {
    width: 30%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .block-version-card-content-v {
    width: 70%;
    height: 23vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    padding-left: 3vw;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .bot-troca-text {
    display: block;
  }
  .form-filter-text.nopad {
    margin-right: 0px;
  }
  .form-filter-de {
    display: none;
  }
  .form-but {
    margin-top: 20px;
    margin-left: 0px;
  }
  .modal-box {
    width: 80vw;
  }
  .modal-header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .list-avatar-img {
    width: 26px;
    height: 26px;
  }
  .list-name {
    width: 100%;
  }
  .list-email {
    width: 100%;
  }
  .list-phone {
    width: 100%;
  }
  .c-r-line {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 1.5vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 10px 50px -18px rgba(0, 0, 0, 0.2);
  }
  .c-g-line {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 1.5vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 10px 50px -18px rgba(0, 0, 0, 0.2);
  }
  .c-b-line {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 1.5vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 10px 50px -18px rgba(0, 0, 0, 0.2);
  }
  .c-y-line {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 1.5vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 10px 50px -18px rgba(0, 0, 0, 0.2);
  }
  .c-gr-line {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 1.5vw;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 10px 50px -18px rgba(0, 0, 0, 0.2);
  }
  .list-titlename {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 2px;
    color: #b9c5cc;
    font-size: 9px;
    line-height: 9px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .list-titlename-card {
    width: 100%;
    margin-bottom: 2px;
    color: #b9c5cc;
    font-size: 9px;
    line-height: 9px;
    text-transform: uppercase;
  }
  .c-b-line-content-card {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-y-line-content-card {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-gr-line-content-card {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .org-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 2.5%;
    padding-left: 2.5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .org-tab-active {
    width: 32%;
    margin-right: 0px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
  }
  .org-tab-desactive {
    width: 32%;
    margin-right: 0px;
    font-size: 13px;
  }
  .org-tab-block {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .org-w-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .org-col-1 {
    width: 100%;
    padding-right: 0%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
  .org-col-2 {
    width: 100%;
    margin-top: 10px;
    padding-right: 0%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    color: #4a90e2;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
  }
  .org-col-3 {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 0%;
    font-size: 14px;
    font-weight: 500;
  }
  .org-col-4 {
    width: 100%;
    padding-right: 0%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    color: #3a3f43;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }
  .org-w-line-content {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .org-w-line-content-header {
    padding-left: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .org-but-normal-act {
    width: 100px;
    margin-right: 10px;
  }
  .org-but-admin-des {
    width: 100px;
  }
  .org-but-admin-act {
    width: 100px;
  }
  .org-but-normal-des {
    width: 100px;
    margin-right: 10px;
  }
  .org-w-header-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .org-col-content-header {
    display: none;
    width: 70%;
    margin: 0px;
    padding: 2vw;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .org-tab2-active {
    margin-right: 0px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
  }
  .org-tab2-desactive {
    margin-right: 0px;
    font-size: 13px;
  }
  .org-tab2-block {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .org2-col-1 {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
  .modal-box2 {
    width: 98%;
    max-width: 98%;
    min-width: 98%;
  }
  .comp-tabs-content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .comp-w-block-left {
    overflow: visible;
    box-shadow: none;
  }
  .comp-w-line {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .comp-w-line-active {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .comp-w-block-comp {
    overflow: visible;
    padding-top: 30px;
    box-shadow: none;
  }
  .comp-block-but {
    padding-bottom: 0px;
  }
  .col38 {
    width: 100%;
  }
  .share-box {
    width: 98%;
    max-width: 98%;
    min-width: 98%;
  }
  .share-tab-active {
    width: 32%;
    margin-right: 0px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
  }
  .share-tab-desactive {
    width: 32%;
    margin-right: 0px;
    font-size: 13px;
  }
  .share-tab-block {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .collum-header-aba {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 2.5%;
    padding-left: 2.5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .collum-header-aba-a {
    padding-right: 2.5%;
    padding-left: 2.5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .list-icon-logs.padr10 {
    background-image: url('/assets/images/video-edit.svg');
  }
  .mob-header {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .mob-sizefull {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 4vw;
    line-height: 4vw;
    text-align: center;
  }
  .mob-sizefull.space {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .mob-icon {
    width: 20%;
  }
  .mob-icon-a {
    width: 20%;
  }
  .line-green {
    width: 100%;
    height: 5px;
    max-width: 100%;
  }
  .c-blue-line-card {
    width: 49%;
    clear: left;
    border-radius: 8px;
    background-color: #f5f5f5;
  }
  .c-red-line-card {
    width: 49%;
    margin-bottom: 3.5vw;
    clear: left;
    border-radius: 8px;
  }
  .c-grey-line-card {
    width: 49%;
    clear: left;
    border-radius: 8px;
  }
  .c-yellow-line-card {
    width: 49%;
    margin-bottom: 3.5vw;
    clear: left;
    border-radius: 8px;
  }
  .c-purple-line-card {
    width: 49%;
    margin-bottom: 3.5vw;
    clear: left;
    border-radius: 8px;
  }
  .mob-card-1 {
    font-size: 2.3vw;
    line-height: 2.3vw;
  }
  .col-content-option {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
  }
  .col-content-option.floot {
    min-height: 55%;
  }
  .grid-icon-lav {
    height: 50%;
  }
  .line-grey {
    width: 100%;
    height: 5px;
    max-width: 100%;
  }
  .conclusion-box {
    padding: 15px;
  }
}

@media screen and (max-width: 767px) {
  .form-block {
    width: 90%;
  }
  .form-fields {
    margin-bottom: 4vw;
  }
  .form-bot-desactive {
    padding: 15px;
  }
  .form-bot-active {
    padding: 15px;
  }
  .form-filed-65 {
    width: 100%;
  }
  .form-filed-35 {
    width: 100%;
  }
  .size-1200 {
    padding-top: 15px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .menu {
    padding-right: 5px;
    padding-left: 5px;
  }
  .bot-grey-right-active {
    display: none;
  }
  .col-6 {
    margin-bottom: 0px;
  }
  .c-all {
    padding-top: 25px;
  }
  .bot-grey-right {
    display: none;
  }
  .c-w-line {
    width: 100%;
  }
  .c-title-block {
    padding-bottom: 10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c-w-p-left {
    display: none;
  }
  .bot-criar-laudo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .bot-upload {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .c-w-background-noeffect {
    width: 100%;
  }
  .form2-perfil-box {
    padding: 15px;
  }
  .form2-plans {
    width: 49%;
    margin-bottom: 2vw;
  }
  .form2-plansselect {
    width: 49%;
    margin-bottom: 2vw;
  }
  .menu-hamburguer {
    width: 50px;
    height: 50px;
    padding-right: 14px;
    padding-left: 14px;
  }
  .s-o-que-e-o-lav {
    margin-bottom: 100px;
  }
  .col30 {
    width: 40%;
    padding: 5px;
  }
  .col30.plabell.noleft {
    padding-right: 0px;
  }
  .col80 {
    width: 70%;
    padding: 5px;
  }
  .col60.nopadright {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .col70 {
    width: 60%;
    padding: 5px;
  }
  .col10 {
    width: 20%;
    padding: 5px;
  }
  .col40 {
    padding: 5px;
  }
  .col40.nopadleft {
    width: 100%;
  }
  .col40.plabelr.flex {
    padding-left: 0px;
  }
  .col20 {
    width: 30%;
    padding: 5px;
  }
  .col33 {
    padding: 5px;
  }
  .col33.pright {
    width: 100%;
  }
  .col33.pright.mbottom {
    margin-bottom: 30px;
  }
  .col90 {
    width: 80%;
    padding: 5px;
  }
  .col75 {
    padding: 5px;
  }
  .col100.flex._30top {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .col100.flexcenter.wrap.distribuido {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .col25 {
    padding: 5px;
  }
  .col50.col-left {
    width: 100%;
    padding-right: 0px;
  }
  .col50.col-right {
    width: 100%;
    padding-left: 0px;
  }
  .col66 {
    padding: 5px;
  }
  .col100-no-vert {
    padding: 5px;
  }
  .col100-top50 {
    padding: 5px;
  }
  .menu-icones-texto {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .menu-foto {
    width: 50px;
    height: 50px;
  }
  .user-absolute {
    top: 57px;
    right: -3px;
  }
  .col-content {
    height: 34vw;
  }
  .c-w-line-photo {
    height: 34vw;
  }
  .col-content-card.floot {
    min-height: 60%;
  }
  .col100-nopad.mbot.wrap {
    margin-bottom: 10px;
  }
  .video-status {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .newsletter-field {
    height: 60px;
  }
  .newsletter-button {
    height: 60px;
  }
  .oque-bloco-branco {
    padding: 10px;
    font-size: 12px;
    line-height: 15px;
  }
  .video-label {
    font-size: 10px;
    line-height: 14px;
  }
  .text-block-50 {
    width: 100%;
  }
  .text-block-25 {
    width: 100%;
  }
  .text-block-33 {
    width: 100%;
  }
  .white-share-block {
    margin-right: 20px;
    margin-left: 20px;
  }
  .share-text {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 15px;
  }
  .share-subtitle {
    line-height: 15px;
  }
  .header-blue {
    height: 55px;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 20px;
  }
  .price-bot-plan-active {
    width: 49%;
    height: 80px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
  }
  .price-bot-text1-ative {
    line-height: 24px;
  }
  .price-bot-text2-ative {
    line-height: 14px;
  }
  .price-bot-plan-des {
    width: 49%;
    height: 80px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
  }
  .price-bot-text1-des {
    line-height: 24px;
  }
  .price-bot-text2-des {
    line-height: 14px;
  }
  .price-text-tit {
    font-size: 17px;
  }
  .price-text-subtit {
    font-size: 13px;
    line-height: 17px;
  }
  .s-portal {
    margin-bottom: 100px;
  }
  .empresa-text2 {
    width: 100%;
    margin-bottom: 10px;
  }
  .empresa-text3 {
    width: 100%;
    margin-bottom: 10px;
  }
  .version-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .block-pastas {
    display: none;
  }
  .bot-excluir-pasta {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .bot-mover-laudo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .m-block-exames {
    width: 100%;
  }
  .m-text-block-25 {
    width: 100%;
  }
  .m-text-block-50 {
    width: 100%;
  }
  .block-exames {
    width: 100%;
  }
  .head-laudos-buscar {
    width: 100%;
  }
  .block-version-card-content {
    height: 34vw;
  }
  .block-version-card-content-v {
    height: 34vw;
  }
  .block-filter {
    width: 100%;
  }
  .modal-bg {
    height: 100vh;
    padding-top: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .modal-box {
    width: 100vw;
  }
  .modal-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    text-align: left;
  }
  .modal-body {
    min-height: 100vh;
  }
  .tab-qrcode-bot {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .c-r-line {
    width: 100%;
  }
  .c-g-line {
    width: 100%;
  }
  .c-b-line {
    width: 100%;
  }
  .c-y-line {
    width: 100%;
  }
  .c-gr-line {
    width: 100%;
  }
  .version-button-folder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .block-organizacoes {
    width: 100%;
    padding-right: 0px;
  }
  .org-col-content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .org-col-content-header {
    height: 34vw;
  }
  .org2-col-1 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .modal-box2 {
    width: 90vw;
  }
  .comp-w-line {
    width: 100%;
  }
  .comp-w-line-active {
    width: 100%;
  }
  .share-box {
    width: 90vw;
  }
  .share-body {
    padding: 10px;
  }
  .video-logo-text {
    font-size: 16px;
    line-height: 16px;
  }
  .video-absolute {
    top: 57px;
    right: -3px;
  }
  .video-play-center {
    width: 45px;
    height: 45px;
    background-size: 22px;
  }
  .text-date {
    width: 100%;
    margin-bottom: 5px;
  }
  .mob-header {
    padding-right: 10px;
    padding-left: 5px;
  }
  .video-red-ball {
    width: 36px;
    height: 36px;
  }
  .video-yellow-ball {
    width: 36px;
    height: 36px;
  }
  .c-yellow-line-card:hover {
    background-color: #fff;
  }
  .c-purple-line-card:hover {
    background-color: #fff;
  }
  .video-purple-ball {
    width: 36px;
    height: 36px;
  }
  .funcions.floot {
    bottom: -18px;
  }
  .video-light-ball {
    width: 32px;
    height: 32px;
  }
  .video-line-play {
    width: 36px;
    height: 36px;
  }
  .video-absolute-copy {
    top: 57px;
    right: -3px;
  }
  .col-content-option.floot {
    min-height: 60%;
  }
  .div-block-14 {
    height: 5000px;
  }
}

@media screen and (max-width: 479px) {
  .section {
    padding-top: 8vh;
  }
  .form-label-100 {
    margin-top: 5px;
    font-size: 12px;
    line-height: 16px;
  }
  .form-zero {
    height: 35px;
    font-size: 15px;
  }
  .form-title-signin {
    font-size: 10vw;
    line-height: 10vw;
  }
  .form-subtitle-sign-in {
    font-size: 4vw;
    line-height: 4vw;
  }
  .form-title {
    margin-bottom: 15px;
  }
  .but-red-a {
    width: 100%;
    margin-right: 0vw;
    margin-bottom: 15px;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .but-blue-a {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .form-block {
    width: 96%;
  }
  .form-buttons {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .form-fields {
    margin-bottom: 4vw;
  }
  .form-field-50 {
    width: 100%;
  }
  .form-bot-desactive {
    width: 100%;
    margin-bottom: 0vw;
    padding: 5vw;
  }
  .form-select {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .form-bot-active {
    width: 100%;
    margin-bottom: 0vw;
    padding: 5vw;
  }
  .form-balls {
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .form-ball-active {
    margin-right: 2vw;
    padding: 2vw 3vw;
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .form-title-divide {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .form-check {
    width: 10px;
    height: 10px;
    margin-left: 8px;
    font-size: 12px;
  }
  .form-ball-desactive {
    margin-right: 2vw;
    padding: 2vw 3vw;
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .form-icon-big {
    width: 40px;
    height: 40px;
  }
  .form-50 {
    width: 100%;
  }
  .form-but-sign-in-3 {
    margin-right: 0px;
    margin-bottom: 4vw;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .form-title-login {
    font-size: 10vw;
    line-height: 10vw;
  }
  .form-but-login-in {
    margin-right: 4vw;
    margin-bottom: 4vw;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .form-filed-65 {
    width: 100%;
  }
  .form-filed-35 {
    width: 100%;
  }
  .form-title-recovery {
    font-size: 10vw;
    line-height: 10vw;
  }
  .but-purple-a {
    width: 100%;
    margin-right: 0vw;
    margin-bottom: 15px;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .logo {
    width: 120px;
  }
  .menu {
    padding-right: 0px;
    padding-left: 0px;
  }
  .c-w-line-top {
    display: none;
  }
  .col-6 {
    line-height: 16px;
  }
  .col-3 {
    font-size: 12px;
  }
  .col-5 {
    color: #7c7c7c;
    line-height: 16px;
    text-decoration: none;
  }
  .bot-busca {
    margin-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .c-title-left {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .bot-pag-right {
    margin-left: 8px;
  }
  .col-4 {
    line-height: 18px;
  }
  .col-1 {
    line-height: 14px;
  }
  .c-w-header {
    padding-right: 4.5%;
    padding-left: 4.5%;
  }
  .c-w-line {
    overflow: hidden;
    margin-bottom: 5vw;
    border-radius: 8px;
    box-shadow: 0 10px 30px -18px rgba(0, 0, 0, 0.5);
  }
  .c-title-text {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .bot-pag-left {
    margin-left: 8px;
  }
  .but-blue {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .c-w-line-content-header {
    padding-left: 15px;
  }
  .bot-criar-laudo {
    margin-left: 0px;
  }
  .c-w-background-noeffect {
    border-radius: 8px;
  }
  .form-field-100 {
    margin-bottom: 4px;
  }
  .form-minha-conta {
    width: 100%;
  }
  .form2-perfil-box {
    width: 100%;
    margin-bottom: 3vw;
    padding: 5vw;
  }
  .form-line-titulo {
    margin-top: 2vw;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 20px;
  }
  .form-bot-update {
    margin-right: 0vw;
    padding: 2vw 4vw;
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .c-w-content2 {
    padding: 2vw;
  }
  .c-w-30 {
    width: 40%;
  }
  .c-w-70 {
    width: 60%;
  }
  .c-w-20 {
    width: 100%;
  }
  .form-bot-remover {
    margin-right: 2vw;
    padding: 2vw 4vw;
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .c-w-15 {
    width: 100%;
  }
  .form-bot-cancel {
    margin-right: 2vw;
    padding: 2vw 4vw;
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .c-w-20-pag {
    width: 100%;
  }
  .c-w-25-plano {
    width: 100%;
  }
  .c-w-25-nota {
    width: 100%;
    margin-top: 8px;
  }
  .size-1200-flutuante {
    margin-top: 12px;
    padding-right: 12px;
    padding-left: 12px;
  }
  .form-line-2titulo {
    margin-top: 2vw;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 20px;
  }
  .col30 {
    width: 100%;
  }
  .col30.plabel.noleft {
    width: 100%;
    padding-right: 0px;
  }
  .col30.plabell.noleft {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .col60 {
    width: 100%;
  }
  .col70 {
    width: 100%;
  }
  .col70._100percent {
    padding: 0px;
  }
  .form-label {
    font-size: 10px;
  }
  .col40 {
    width: 100%;
  }
  .col40.nopadleft {
    padding-right: 0px;
    padding-left: 0px;
  }
  .col33 {
    width: 100%;
  }
  .col100 {
    margin-top: 5px;
  }
  .col100.down20 {
    margin-bottom: 10px;
  }
  .col100.top50 {
    margin-top: 20px;
  }
  .col100.wrap.center {
    margin-top: 0px;
  }
  .col25 {
    width: 100%;
  }
  .col25.pright.mbottom {
    margin-bottom: 0px;
  }
  .col25.prightleft.mbottom {
    margin-bottom: 0px;
  }
  .col25.pleft.mbottom {
    margin-bottom: 0px;
  }
  .col50 {
    width: 100%;
  }
  .col50.top20 {
    margin-top: 0px;
  }
  .col50.right {
    color: #a1a1a1;
  }
  .col50.pright {
    padding-right: 5px;
  }
  .col66 {
    width: 100%;
  }
  .form-ball-pass {
    margin-right: 2vw;
    padding: 2vw 3vw;
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .form-text {
    font-size: 15px;
  }
  .but-grey-a {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .but-grey {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .but-purple {
    width: 100%;
    margin-right: 0vw;
    margin-bottom: 15px;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .but-red {
    width: 100%;
    margin-right: 0vw;
    margin-bottom: 15px;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .menu-chat {
    display: none;
  }
  .menu-icones-texto {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .menu-foto {
    width: 40px;
    height: 40px;
  }
  .user-absolute {
    top: 47px;
    right: -8px;
  }
  .lang-flag {
    display: none;
  }
  .lang-flagbr {
    display: none;
  }
  .col-photo {
    overflow: hidden;
    width: 100%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
  }
  .col-content {
    width: 100%;
    height: 60vw;
  }
  .c-w-line-photo {
    height: 30vw;
  }
  .c-green-line-card {
    width: 49%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-radius: 8px;
  }
  .col-photo-card {
    overflow: hidden;
    width: 100%;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
  }
  .col-content-card {
    padding-top: 2vw;
    padding-right: 2vw;
    padding-left: 2vw;
  }
  .col-1-card {
    width: 100%;
    line-height: 14px;
  }
  .col-2-card {
    margin-top: 4px;
    font-size: 14px;
    line-height: 14px;
  }
  .col-3-card {
    margin-top: 5px;
    font-size: 12px;
  }
  .col-4-card {
    margin-bottom: 1px;
    line-height: 18px;
  }
  .col-6-card {
    margin-top: 15px;
    line-height: 16px;
  }
  .col100-nopad {
    padding: 0px;
  }
  .col100-nopad.form-wrap {
    padding-right: 0px;
    padding-left: 0px;
  }
  .col100-nopad.mbot.wrap {
    margin-bottom: 5px;
  }
  .video-title {
    margin-bottom: 2px;
    font-size: 18px;
    line-height: 20px;
  }
  .video-id {
    font-size: 11px;
    line-height: 11px;
    font-weight: 700;
  }
  .header-100-100 {
    display: none;
  }
  .white-share-block {
    margin: 10px 20px;
  }
  .form-bot-enviar {
    width: 100%;
    height: 40px;
    margin-right: 0vw;
    padding: 3vw 8vw;
    font-size: 5vw;
    line-height: 3vw;
  }
  .share-text {
    margin-bottom: 5px;
  }
  .share-subtitle {
    font-size: 14px;
    line-height: 14px;
  }
  .header-blue {
    height: 50px;
    font-size: 18px;
    line-height: 18px;
  }
  .white-permission-block {
    margin: 10px;
  }
  .form-col33 {
    width: 100%;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .form-col33.pleft {
    padding-left: 0px;
  }
  .form-col33.pleftright {
    padding-right: 0px;
    padding-left: 0px;
  }
  .form-col100 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .form-col40 {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .form-col40.pleft {
    padding-left: 0px;
  }
  .form-col60 {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .form-col60.pright {
    padding-right: 0px;
  }
  .form-col20 {
    width: 50%;
  }
  .form-col20.pleft {
    width: 50%;
  }
  .price-box {
    padding: 15px;
  }
  .price-bot-plan-active {
    width: 100%;
    margin-bottom: 10px;
  }
  .price-bot-plan-des {
    width: 100%;
    margin-bottom: 10px;
  }
  .price-text-tit {
    font-size: 16px;
    line-height: 18px;
  }
  .price-box-title {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 30px;
  }
  .price-box-price1 {
    font-size: 36px;
  }
  .s-preco {
    margin-bottom: 100px;
  }
  .white-form-block {
    padding: 20px;
  }
  .form-col50 {
    width: 100%;
  }
  .form-col50.pleft {
    padding-left: 0px;
  }
  .form-col50.pright {
    padding-right: 0px;
  }
  .form-but-send {
    margin-right: 4vw;
    margin-bottom: 4vw;
    padding: 3vw 5vw;
    font-size: 4vw;
    line-height: 4vw;
  }
  .rodape-text {
    text-align: center;
  }
  .video-control-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .video-control-rbut {
    width: 30%;
  }
  .video-control-lbut {
    width: 30%;
  }
  .video-control-cbut {
    width: 40%;
  }
  .block-pastas {
    width: 100%;
    padding-right: 0px;
  }
  .folder-header {
    padding-right: 4.5%;
    padding-left: 4.5%;
  }
  .form-create-folder-input {
    height: 35px;
    font-size: 15px;
  }
  .form-col25 {
    width: 100%;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .form-col25.pleft {
    padding-left: 0px;
  }
  .head-laudos-botoes {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .block-version-title {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .block-version-card-photo {
    overflow: hidden;
    width: 100%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
  }
  .block-version-card-content {
    width: 100%;
    height: 60vw;
  }
  .version-photo {
    height: 30vw;
  }
  .block-version-card-photo-v {
    overflow: hidden;
    width: 100%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
  }
  .block-version-card-content-v {
    width: 100%;
    height: 60vw;
  }
  .bot-troca-a {
    margin-right: 5px;
    margin-left: 0px;
  }
  .form-filter-ate {
    width: 10%;
    margin-top: 10px;
  }
  .form-filter-de {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 10%;
    margin-top: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .form-filter-block-date {
    width: 90%;
    margin-top: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .modal-body {
    padding-right: 5px;
    padding-left: 5px;
  }
  .user-name {
    left: -12px;
    font-size: 9px;
    line-height: 9px;
  }
  .tab2-item {
    padding: 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .tab2-item-a {
    padding: 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .c-r-line {
    overflow: hidden;
    margin-bottom: 5vw;
    border-radius: 8px;
    box-shadow: 0 10px 30px -18px rgba(0, 0, 0, 0.5);
  }
  .c-g-line {
    overflow: hidden;
    margin-bottom: 5vw;
    border-radius: 8px;
    box-shadow: 0 10px 30px -18px rgba(0, 0, 0, 0.5);
  }
  .c-b-line {
    overflow: hidden;
    margin-bottom: 5vw;
    border-radius: 8px;
    box-shadow: 0 10px 30px -18px rgba(0, 0, 0, 0.5);
  }
  .c-y-line {
    overflow: hidden;
    margin-bottom: 5vw;
    border-radius: 8px;
    box-shadow: 0 10px 30px -18px rgba(0, 0, 0, 0.5);
  }
  .c-gr-line {
    overflow: hidden;
    margin-bottom: 6vw;
    border-radius: 8px;
    box-shadow: 0 10px 30px -18px rgba(0, 0, 0, 0.5);
  }
  .video-control-cbut2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .block-organizacoes {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
  }
  .org-header {
    padding-right: 4.5%;
    padding-left: 4.5%;
  }
  .org-col-1 {
    line-height: 14px;
  }
  .org-col-3 {
    font-size: 12px;
  }
  .org-col-4 {
    line-height: 18px;
  }
  .org-col-content {
    margin-right: 10px;
    margin-left: 10px;
  }
  .org-w-line-content-header {
    padding-left: 15px;
  }
  .org-but-invite {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .org-busca {
    padding-right: 10px;
  }
  .org-w-header-line {
    padding-right: 4.5%;
    padding-left: 4.5%;
  }
  .org-col-content-header {
    width: 100%;
    height: 60vw;
  }
  .org2-col-1 {
    line-height: 14px;
  }
  .modal-box2 {
    width: 94vw;
  }
  .org2-col-0 {
    width: 30px;
    line-height: 14px;
  }
  .share-box {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .share-body {
    padding: 0px;
  }
  .share-tab-active {
    height: 40px;
    font-size: 11px;
    line-height: 12px;
  }
  .share-tab-desactive {
    height: 40px;
    font-size: 11px;
    line-height: 11px;
  }
  .video-logo-empresa {
    width: 40px;
    height: 40px;
  }
  .video-card-empresa {
    margin-bottom: 10px;
  }
  .video-logo-text {
    font-size: 14px;
    line-height: 14px;
  }
  .video-logo-emissor {
    width: 40px;
    height: 40px;
  }
  .video-absolute {
    top: 47px;
    right: -8px;
  }
  .video-green-ball {
    margin-right: 2px;
  }
  .collum-header-aba {
    padding-right: 4.5%;
    padding-left: 4.5%;
  }
  .collum-header-aba-a {
    padding-right: 4.5%;
    padding-left: 4.5%;
  }
  .form-field-zero {
    margin-bottom: 4px;
  }
  .video-play-center {
    width: 40px;
    height: 40px;
    background-size: 20px;
  }
  .date-status {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .text-date {
    font-size: 10px;
    line-height: 10px;
  }
  .mob-sizefull {
    font-size: 5vw;
    line-height: 5vw;
  }
  .mob-icon {
    font-size: 14px;
    line-height: 14px;
  }
  .mob-icon-image {
    width: 25px;
    height: 25px;
  }
  .mob-icon-txt {
    font-size: 12px;
    line-height: 22px;
  }
  .c-blue-line-card {
    position: relative;
    width: 49%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-radius: 8px;
  }
  .video-blue-ball {
    margin-right: 2px;
  }
  .c-red-line-card {
    width: 49%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-radius: 8px;
  }
  .video-red-ball {
    width: 32px;
    height: 32px;
  }
  .c-grey-line-card {
    width: 49%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-radius: 8px;
  }
  .video-grey-ball {
    margin-right: 2px;
  }
  .video-yellow-ball {
    width: 32px;
    height: 32px;
  }
  .c-yellow-line-card {
    width: 49%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-radius: 8px;
  }
  .c-purple-line-card {
    width: 49%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-radius: 8px;
  }
  .video-purple-ball {
    width: 32px;
    height: 32px;
  }
  .mob-card-1 {
    font-size: 3.5vw;
    line-height: 3.7vw;
    font-weight: 700;
  }
  .mob-card-4 {
    padding-right: 1vw;
    padding-bottom: 2vw;
    padding-left: 2vw;
  }
  .head-back {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
  .video-line-play {
    width: 32px;
    height: 32px;
  }
  .video-absolute-copy {
    top: 47px;
    right: -8px;
  }
  .col-content-option {
    z-index: 2;
    width: 100%;
  }
  .grid-trash {
    width: 100%;
    height: 40px;
    min-height: 30px;
    padding-top: 0px;
    padding-left: 40px;
    background-position: 10px 50%;
    background-size: 20px 20px;
    text-align: left;
  }
  .grid-text {
    margin-top: 0px;
  }
  .grid-icon-lav {
    width: 100%;
    height: 40px;
    min-height: 30px;
    padding-top: 0px;
    padding-left: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-position: 10px 50%;
    background-size: 20px 20px;
    text-align: left;
  }
  .grid-icon-edit {
    width: 100%;
    height: 40px;
    min-height: 30px;
    padding-top: 0px;
    padding-left: 40px;
    background-position: 10px 50%;
    background-size: 20px 20px;
    text-align: left;
  }
  .grid-share {
    width: 100%;
    height: 40px;
    min-height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-position: 10px 50%;
    background-size: 20px 20px;
    text-align: left;
  }
  .grid-log {
    width: 100%;
    height: 40px;
    min-height: 30px;
    padding-top: 0px;
    padding-left: 40px;
    background-position: 10px 50%;
    background-size: 20px 20px;
    text-align: left;
  }
  .grid-permission {
    width: 100%;
    height: 40px;
    min-height: 30px;
    padding-top: 0px;
    padding-left: 40px;
    background-position: 10px 50%;
    background-size: 20px 20px;
    text-align: left;
  }
  .conclusion-box {
    padding: 8px 9px;
    font-size: 14px;
    line-height: 18px;
  }
  .video-control-block-thumb {
    display: none;
    width: 100%;
  }
}

