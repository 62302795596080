@import url('../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css');
// @import 'assets/themes/style.default.css';

.ant-layout-sider-trigger { background-color: #384759 !important; }
.pad_def { padding: 20px;}
.ql-editor { min-height:120px; }
.ql-editor p { line-height: 23px; }

.ant-ribbon {
  white-space: normal !important;
  height: auto !important;
  padding: 8px;
  top: 16px;
  line-height: 18px;
  opacity: 0.95;
  text-shadow: 1px 1px 0px rgba(0,0,0,0.5);
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}


@media screen and (max-width: 400px) {
  .pad_def { padding: 10px;}
}

// ============== 

.ng-valid[required], .ng-valid.required  { border-left: 5px solid #42A948 !important; /* green */ }
.ng-invalid:not(form)  { border-left: 5px solid #B71C1C !important; /* red */ }

.inpInvalid { border-left: 5px solid #B71C1C !important; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s;
    transition-property: background-color, color;
}

.c-signature {
  background-image: none;
}

.list-avatar-img {
  background-image: url('/assets/images/user.png');
}
.tab-qrcode-image {
  background-image: none;
}
.form-label {
  color: #607D8B !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  margin-bottom: 3px !important;
}

.form-label-100 {
  color: #607D8B !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  margin-bottom: 3px !important;
}

.form-field {
  background-color: #e8f0fe !important;
}
.form-text {
  background-color: #e8f0fe !important;
}

.form2-label-100 {
  padding-top: 0px !important;
  padding-left: 8px !important;
  color: #90A4AE !important;
  font-size: 16px !important;
  line-height: 18px !important;
}

.form2-line-vertical {
  background-color: #CFD8DC  !important;
}

.c-w-line-photo-card-web { background-size: contain !important; }
.c-w-line-photo-card-mobile { background-size: contain; height: 50vw !important; margin-top: 10px !important; }
.col-photo-card-web { padding-left: 15px !important; }
.col-photo-card-mobile { padding: 0px !important; }

.bg-y-card { background-color: #ffffe1 !important; }
.bg-g-card { background-color: #d6ffe8 !important; }
.bg-w-card { background-color: #fff !important; }
.bg-b-card { background-color: #e0f6ff !important; }

.version-button-folder { background-color: #607D8B !important; height: 70px !important; border-radius: 8px 8px 0px 0px !important; }
.folder-header { background-color: #fff;height: 10px !important; }

.block-organizacoes { margin-top: 24px !important; }
.block-organizacoes-aberto { margin-top: 10px !important; }

.dropzone {
  width: 100% !important;
  min-height: 80px !important;
  border: 2px dashed #37474F !important;
  background-color: #e8f0fe !important;
  font-size: 21px !important;
  color: #37474F !important;
  border-color: #37474F !important;
  text-align: center;
  padding-top: 25px;
}

*:focus {
  outline: none;
}

.cursor {
  cursor: pointer !important;
}

.info-box {
  padding: 20px;
  border-radius: 12px;
  background-color: #43A047;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}

iframe {
  width: 100%;
}


.cardRTC {
  width: 100%;
  padding: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: flex-start;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 6px 50px -10px rgba(0, 0, 0, 0.3);
}



.folder-delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #D50000;
  border-radius: 4px;
  color: #D50000;
  font-weight: 500;
  text-align: center;
}

.folder-delete:hover {
  background-color: #D50000;
  color: #fff;
}

.maxWidth600 {
  max-width: 600px !important;
}
.maxWidth320 {
  max-width: 320px !important;
}

.menu-link-lavsw {
  padding: 10px 12px;
  border: 1px none #000;
  border-radius: 8px;
  background-color: #D32F2F;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.link-menu-lavsw-ativo {
  padding: 10px 12px;
  border: 1px none #000;
  border-radius: 8px;
  background-color: #D50000;
  color: #fff;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}

.c-photo {
  background-image: none;
}

.org-input {
  color: #fff;
}

.ql-container {
  font-size: 16px !important;
  color: #263238 !important;
}

.share-tab-active {
  background-color: #707C8E !important;
}
.org-w-header {
  background-color: #707C8E !important;
}
.org-input {
  border-color: #707C8E !important;
}


.mob-menu-bg {
    bottom: 0 !important;
    height: auto !important;
}

.enterprise-icon {
  min-width: 40px !important;
  min-height: 40px !important;
}

.modal-header {
  z-index: 999;
}

.ajsTop {
  // margin-top: -80px !important;
  padding: 10px 10px 10px 10px !important;
}

.video-background { 
  border-top-left-radius: 0px; 
  border-top-right-radius: 0px;
}

.mob-card-4 {
  z-index: 1;
}

.c-w-line-photo-card { background-size: cover; }
.c-grey-line-card { width: 32% !important; box-shadow: 0 20px 40px -10px rgba(0,0,0,.3); }  	
.c-blue-line-card { width: 32% !important; box-shadow: 0 20px 40px -10px rgba(0,0,0,.3); } 
.c-green-line-card { width: 32% !important; box-shadow: 0 20px 40px -10px rgba(0,0,0,.3); } 	
.list-block-name { font-size: 10px !important; line-height: 11px; } 
.c-g-line:hover { background-color: #A5D6A7; }
.c-g-line { background-color: #c8fade; }

@media screen and (max-width: 991px) {
  .c-grey-line-card { width: 49% !important; box-shadow: 0 20px 40px -10px rgba(0,0,0,.3); }  	
  .c-blue-line-card { width: 49% !important; box-shadow: 0 20px 40px -10px rgba(0,0,0,.3); } 
  .c-green-line-card { width: 49% !important; box-shadow: 0 20px 40px -10px rgba(0,0,0,.3); } 
}

